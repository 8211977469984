import { faUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faUpRightFromSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  openLink: {
    color: colours.bodyText,
    ...gStyles.avalonBold,
    fontSize: "0.875rem",
    height: "2.313rem",
    width: "100%",
    display: "grid",
    placeItems: "center",
    marginTop: "0.5rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  openWebsite: {
    ...gStyles.avalonBold,
    display: "flex",
    gap: "0.5rem",
    alignItems: "Center",
    "--fa-secondary-opacity": 1,
    "--fa-primary-color": colours.bodyText,
    "--fa-secondary-color": colours.bodyText,
  },
};

export default function InsightNavigationOpenWebsite(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { webUrl } = props;

  if (!webUrl) {
    return null;
  }

  return (
    <div className={css(styles.openLink)}>
      <a
        className={css(styles.openWebsite)}
        target="_blank"
        href={webUrl}
        rel="noreferrer noopener"
      >
        Open Website <FontAwesomeIcon icon={faUpRightFromSquare} />
      </a>
    </div>
  );
}

InsightNavigationOpenWebsite.propTypes = {
  webUrl: PropTypes.string,
};
