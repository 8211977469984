import PropTypes from "prop-types";
import React from "react";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  cardContainer: {
    position: "relative",
    border: `1px solid var(--color-neutral-d95)`,
    borderRadius: "0.75rem",
    padding: "1rem",
    width: "100%",
    flex: 1,

    ":last-child": {
      marginBottom: 0,
    },
    [ScreenSizes.mdOnly]: {
      width: "auto",
    },
  },
  hide: {
    visibility: "hidden",
    opacity: 0,
    transition: "visibility .25s, opacity .25s linear",
  },
};

export default function InsightCard(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { children, fade } = props;

  return (
    <div className={css(styles.cardContainer, fade && styles.hide)}>
      {children}
    </div>
  );
}

InsightCard.propTypes = {
  children: PropTypes.node,
};
InsightCard.defaultProps = {
  children: null,
};
