import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import InsightNavigationOpenWebsite from "./InsightNavigationOpenWebsite";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  navigationContainer: {
    display: "none",

    [createBreakpoint({ min: 1170 })]: {
      width: "100%",
      display: "flex",
      flexDirection: "Columns",
      justifyContent: "Center",
      position: "Relative",
    },
  },
  container: {
    position: "relative",
  },
  floatingContainer: {
    position: "fixed",
    top: "10rem",
  },
  navSubContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "0 max-content",
    alignItems: "start",
  },
  contactButton: {
    color: colours.primary,
    ...gStyles.avalonBold,
    fontSize: "0.875rem",
    borderRadius: 8,
    border: `1px solid ${colours.primary}`,
    outline: "none",
    height: "2.313rem",
    width: "100%",
    display: "grid",
    placeItems: "center",
    margin: "0 auto",
    marginTop: "2.313rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  getPro: {
    display: "flex",
    width: "9.8125rem",
    height: "2.1875rem",
    padding: "0.4375rem 0rem 0.375rem 0rem",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
    borderRadius: "0.5rem",
    background: "linear-gradient(92deg, #250675 4.46%, #5F0B9F 99.48%)",
    boxShadow:
      "2px -2px 0px 0px #EF3280, -2px 1px 0px 0px #3CD0EE, 2px 2px 0px 0px #FFEF84",
    ...gStyles.avalonBold,
    color: "#FDFDFD",
    textAlign: "center",
    fontSize: "0.875rem",
    lineHeight: "1.375rem",
    marginTop: "1rem",
    cursor: "pointer",
  },
};

export const CONTACTS_ID = "Contacts";

export default function InsightNavigation(props) {
  const {
    children,
    contactRef,
    entity,
    entityType,
    setActiveLocation,
    setIsNotScrolling,
    shuffleNav,
  } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const hasPro = useUserHasPro();

  const entityId = entity && entity?.get("id");
  const entityTitle = entity && entity?.get("title");
  const entityWebsite =
    hasPro && ((entity && entity?.get("web_url")) || entity?.get("url"));

  const handleClick = useCallback(
    (id, ref) => {
      sendGAEvent({
        action: "insightNavigationclicked",
        sectionClicked: id,
        entityType,
        entityId: entityId,
        entityTitle: entityTitle,
      });

      setIsNotScrolling(false);
      setActiveLocation(id);

      const top = ref?.current?.offsetTop + 100;
      window?.scroll({ top, behavior: "smooth" });

      setTimeout(() => {
        setIsNotScrolling(true);
      }, 1000);
    },
    [entityId, entityTitle, entityType, setActiveLocation, setIsNotScrolling]
  );

  const onDemoRequestSubmitted = useCallback((action) => {
    sendGAEvent({
      action: "submittedProLeadForm",
      entity_type: "podcast",
      page: "PodcastInsightsNavigationCTA",
      context: "ProLeadForm",
    });
  }, []);

  const onLearnMoreClick = useCallback(() => {
    sendGAEvent({
      action: `openedProLeadForm`,
      entity_type: "podcast",
      page: "NonProPodcastNavigationCTA",
      entity_id: entity?.get("id"),
      entity_title: entity?.get("title"),
      context: "ProLeadForm",
    });
    showModal("proLeadForm", {
      onDemoRequestSubmitted: () => onDemoRequestSubmitted(),
    });
  }, [onDemoRequestSubmitted, entity, showModal]);

  return (
    <div className={css(styles.navigationContainer)}>
      <div
        className={css(
          styles.container,
          shuffleNav && styles.floatingContainer
        )}
      >
        {children({
          handleClick,
          navSubContainerClass: css(styles.navSubContainer),
        })}

        {hasPro ? (
          <>
            <button
              className={css(styles.contactButton)}
              onClick={() => handleClick(CONTACTS_ID, contactRef)}
            >
              Contact Podcast
            </button>
            <InsightNavigationOpenWebsite webUrl={entityWebsite} />
          </>
        ) : (
          <button onClick={onLearnMoreClick} className={css(styles.getPro)}>
            Get Pro
          </button>
        )}
      </div>
    </div>
  );
}

InsightNavigation.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entityType: PropTypes.string,
  entityWebsite: PropTypes.string,
  children: PropTypes.func,
  contactRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(PropTypes.element) }),
  ]),
  setActiveLocation: PropTypes.func,
  setIsNotScrolling: PropTypes.func,
  shuffleNav: PropTypes.bool,
};
