import { format } from "date-fns/format";

import { SHORT_DATE_ONLY_FORMAT } from "constants/date";

export default function formatFieldDate(podcast, field) {
  if (podcast && podcast.get(field)) {
    const formatedDate = format(
      new Date(podcast.get(field)),
      SHORT_DATE_ONLY_FORMAT
    );
    if (formatedDate && formatedDate !== "Invalid Date") {
      return formatedDate;
    }
  }
  return null;
}
