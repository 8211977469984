import PropTypes from "prop-types";
import React from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

export default function InsightNavigationBar(props) {
  const { numberElements } = props;

  const navBarStyles = {
    navPath: {
      height: 36.8 * (numberElements - 1),
      width: "5px",
      borderRadius: 25,
      background: colours.navPath,
      marginTop: "0.75rem",
    },
  };

  const { styles, css } = useStyles(navBarStyles, props);

  if (!numberElements) {
    return null;
  }

  return <div className={css(styles.navPath)} data-testid="navPath" />;
}

InsightNavigationBar.propTypes = {
  numberElements: PropTypes.number,
};

InsightNavigationBar.defaultProps = {
  numberElements: 0,
};
