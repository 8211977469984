import { Map } from "immutable";
import PropTypes from "prop-types";

import EntityOverviewTemplate from "../EntityOverviewTemplate";

const NetworkOverview = (props) => {
  const { network } = props;

  return (
    <EntityOverviewTemplate
      dataId="network-overview"
      {...props}
      entity_type="network"
      entity={network}
      subtitle={network.get("subtitle_short")}
    />
  );
};

NetworkOverview.propTypes = {
  network: PropTypes.instanceOf(Map).isRequired,
};

export default NetworkOverview;
