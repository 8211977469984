import colours from "styles/colours";

export const getPoliticalSkew = ({
  politicalSkew,
  politicalLeanDegree = 10,
}) => {
  switch (politicalSkew) {
    case "Left": {
      if (politicalLeanDegree === 1) {
        return {
          title: "Low Left",
          color: colours.politicalLeftStrong,
          icon1: colours.politicalLeftStrong,
          icon2: colours.politicalLeftFaint,
          icon3: colours.politicalLeftFaint,
          backgroundColor: colours.politicalLeftFaint,
        };
      }
      if (politicalLeanDegree === 2) {
        return {
          title: "Medium Left",
          color: colours.politicalLeftStrong,
          icon1: colours.politicalLeftStrong,
          icon2: colours.politicalLeftStrong,
          icon3: colours.politicalLeftFaint,
          backgroundColor: colours.politicalLeftFaint,
        };
      }
      if (politicalLeanDegree === 3) {
        return {
          title: "High Left",
          color: colours.politicalLeftStrong,
          icon1: colours.politicalLeftStrong,
          icon2: colours.politicalLeftStrong,
          icon3: colours.politicalLeftStrong,
          backgroundColor: colours.politicalLeftSubtle,
        };
      }
      return {
        title: "Low Left",
        color: colours.politicalLeftStrong,
        icon1: colours.politicalLeftStrong,
        icon2: colours.politicalLeftFaint,
        icon3: colours.politicalLeftFaint,
        backgroundColor: colours.politicalLeftFaint,
      };
    }
    case "Center":
      return {
        title: "Neutral/Mixed",
        color: colours.politicalNeutralStrong,
        icon1: colours.politicalLeftFaint,
        icon2: colours.politicalNeutralFaint,
        icon3: colours.politicalRightFaint,
        backgroundColor: colours.politicalNeutralFaint,
      };
    case "Right": {
      if (politicalLeanDegree === 1) {
        return {
          title: "Low Right",
          color: colours.politicalRightStrong,
          icon1: colours.politicalRightStrong,
          icon2: colours.politicalRightFaint,
          icon3: colours.politicalRightFaint,
          backgroundColor: colours.politicalRightFaint,
        };
      }
      if (politicalLeanDegree === 2) {
        return {
          title: "Medium Right",
          color: colours.politicalRightStrong,
          icon1: colours.politicalRightStrong,
          icon2: colours.politicalRightStrong,
          icon3: colours.politicalRightFaint,
          backgroundColor: colours.politicalRightFaint,
        };
      }
      if (politicalLeanDegree === 3) {
        return {
          title: "High Right",
          color: colours.politicalRightStrong,
          icon1: colours.politicalRightStrong,
          icon2: colours.politicalRightStrong,
          icon3: colours.politicalRightStrong,
          backgroundColor: colours.politicalRightSubtle,
        };
      }
      return {
        title: "Low Right",
        color: colours.politicalRightStrong,
        icon1: colours.politicalRightStrong,
        icon2: colours.politicalRightFaint,
        icon3: colours.politicalRightFaint,
        backgroundColor: colours.politicalRightFaint,
      };
    }
    default:
      return {
        title: "",
        color: "",
        icon1: "",
        icon2: "",
        icon3: "",
        backgroundColor: "",
      };
  }
};
