import { equals } from "utils/misc";

export default function getPeriodFromDays(days, options = {}) {
  const {
    allowPer = true,
    allowBi = false,
    allowTri = false,
    allowQuarterly = true,
    exactOnly = true,
  } = options;
  const getPeriod = (period, parentPeriod, currentPeriod) => {
    const times = exactOnly ? period / days : Math.floor(period / days);
    if (times > 1) {
      if (allowPer && !exactOnly) {
        return `${Math.floor(times)} per ${parentPeriod}`;
      } else if (equals(times, 2) && allowBi) {
        return `Bi${currentPeriod}`;
      } else if (equals(times, 3) && allowTri) {
        return `Tri${currentPeriod}`;
      } else if (
        equals(times, 4) &&
        parentPeriod === "year" &&
        allowQuarterly
      ) {
        return "Quarterly";
      }
    }

    return !exactOnly || times === 1 ? currentPeriod : "";
  };
  const year = 356;
  const month = 28;
  const week = 7;
  const day = 1;

  if (days >= year) {
    const years = exactOnly ? days / year : Math.floor(days / year);
    if (years > 1) {
      if (!exactOnly || years === Math.floor(years)) {
        return `${Math.floor(years)}-Yearly`;
      }
    } else if (years === 1) {
      return "Yearly";
    } else {
      return "";
    }
  }
  if (days >= month * 1.5) {
    return getPeriod(year, "year", "Yearly");
  }
  if (days >= month * 0.9) {
    return !exactOnly || days === month ? "Monthly" : "";
  }
  if (days >= week * 1.5) {
    return getPeriod(month, "month", "Monthly");
  }
  if (days >= week * 0.9) {
    return !exactOnly || days === week ? "Weekly" : "";
  }
  if (days >= day * 1.5) {
    return getPeriod(week, "week", "Weekly");
  }
  return !exactOnly || days === 1 ? "Daily" : "";
}
