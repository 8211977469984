import useEntityCard from "../useEntityCard";
import NetworkCompactCard from "./NetworkCompactCard";
import NetworkDesktopCard from "./NetworkDesktopCard";
import NetworkMobileCard from "./NetworkMobileCard";
import NetworkOverview from "./NetworkOverview";

const CARD_CONFIG = {
  desktop: NetworkDesktopCard,
  mobile: NetworkMobileCard,
  overview: NetworkOverview,
  compact: NetworkCompactCard,
};

const NetworkCardContainer = (props) => {
  const { CardType, cardProps } = useEntityCard(CARD_CONFIG, "network", props);

  return <CardType {...props} {...cardProps} />;
};

export default NetworkCardContainer;
