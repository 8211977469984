import { userHasPoliticalSkewPermission } from "utils/entity/user";

import useLoggedInUser from "hooks/useLoggedInUser";

const useUserHasPoliticalSkewPermission = () => {
  const user = useLoggedInUser();

  return userHasPoliticalSkewPermission(user);
};

export default useUserHasPoliticalSkewPermission;
