import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";

import PodchaserProLogo from "components/Common/Branding/PodchaserProLogo";
import SpiralBackground from "components/Pro/SpiralBackground";

import { insightStyles } from "../InsightsStyles";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const desktopSize = ScreenSizes.xlAndAbove;

const baseStyles = {
  ...insightStyles,
  insightsSection: {
    ...insightStyles.insightsSection,
    paddingLeft: "1rem",
    paddingRight: "1rem",

    ":last-child": {
      marginBottom: 8, // to shwow chromatic abberation border inside container overflow cutoff
    },
    [desktopSize]: {
      padding: 0,
    },
  },
  banner: {
    ...gStyles.gradientBackground,
    ...gStyles.chromaticAbberationBlock,
    borderRadius: 10,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem",
    textAlign: "center",
    position: "relative",

    [desktopSize]: {
      flexDirection: "row",
      textAlign: "left",
    },
    ":hover .bannerIcon": {
      transform: "translateX(5px)",
      transition: generateTransition({ targets: ["transform"] }),
    },
  },
  bannerLogo: {
    flex: "0 0 auto",
    margin: "0 0 1rem",

    [desktopSize]: {
      margin: "0 2rem 0 0",
    },
  },
  bannerText: {
    ...gStyles.avalonBold,
    flex: "1 1 auto",
    margin: "0 0 1rem",

    [desktopSize]: {
      margin: "0 1.5rem 0 0",
    },
  },
  bannerIcon: {
    fontSize: 22,
    lineHeight: 1,
    transition: generateTransition({ targets: ["transform"] }),
  },
};

const InsightsProCTA = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return (
    <section className={css(styles.insightsSection)}>
      <a className={css(styles.banner)} href="/pro">
        <SpiralBackground
          position="bottom center"
          size="100% auto"
          fixed={false}
        />
        <span className={css(styles.bannerLogo)}>
          <PodchaserProLogo height={28} linked={false} showPodchaserLogo />
        </span>
        <span className={css(styles.bannerText)}>
          Access reach, contacts, demographics, and more across millions of
          podcasts!
        </span>
        <span className="bannerIcon">
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
      </a>
    </section>
  );
};

export default InsightsProCTA;
