import { faArrowUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import InsightCard from "../../../components/InsightCard";
import ReachContent, { firstContainerStyles } from "../ReachContent";
import ReachContentHelpIcon from "../ReachContentHelpIcon";
import ReachContentIcon from "../ReachContentIcon";
import ReachPopUp from "../ReachPopUp";

import sendGAEvent from "utils/sendGAEvent";

import useOutsideClickHandler from "hooks/useOutsideClickHandler";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import faReachAll from "styles/icons/faReachAll";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  ...firstContainerStyles,
  data: {
    ...gStyles.avalonBold,
    fontSize: "1.5rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    color: "var(--color-neutral-d5)",
    textAlign: "center",
    margin: 0,
  },
  popUpTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  para: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  paraBold: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  link: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.primary,
    textAlign: "center",
    margin: "0 0 0 0.188rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  button: {
    width: "max-content",
    border: `1px solid ${colours.borderGrey}`,
    padding: "0.5rem 1rem",
    borderRadius: 25,
    ...gStyles.avalonBold,
    fontSize: "1rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: "1rem auto 0 auto",
  },
};

const ReachPopUpStyles = {
  outer: {
    [ScreenSizes.mdAndAbove]: {
      bottom: 30,
    },
  },
};

const ReachPopUpWithWarningStyles = {
  outer: {
    [ScreenSizes.mdAndAbove]: {
      bottom: 30,
      marginLeft: -116,
    },
    [createBreakpoint({ min: 920 })]: {
      marginLeft: -120,
    },
  },
};

const overrideCardStyles = {
  cardContainer: {
    borderRadius: 0,
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",

    [ScreenSizes.mdAndAbove]: {
      borderTopRightRadius: 12,
      borderLeft: `1px solid var(--color-neutral-d95)`,
    },

    [createBreakpoint({ min: 920 })]: {
      borderRadius: 0,
      borderTop: `1px solid var(--color-neutral-d95)`,
      borderBottom: `1px solid var(--color-neutral-d95)`,
      borderRight: "none",
      borderLeft: "none",
    },
  },
};

const overrideCardStylesWithWarning = {
  cardContainer: {
    borderLeft: "none",
    borderRight: "none",
    borderTop: "none",

    borderRadius: 0,

    [ScreenSizes.mdAndAbove]: {
      borderTopRightRadius: 12,
      borderLeft: `1px solid var(--color-neutral-d95)`,
    },

    [createBreakpoint({ min: 920 })]: {
      borderRadius: 0,
      borderTop: `1px solid var(--color-neutral-d95)`,
      borderBottom: `1px solid var(--color-neutral-d95)`,
      borderRight: "none",
      borderLeft: "none",
    },
  },
};

export default function MonthlyListeners(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const {
    reachEstimate,
    showPopUp,
    handlePopUp,
    podcastId,
    isVerified,
    showWarning,
  } = props;
  const { getWindowWidth } = useWindowSize();

  const currentSize = getWindowWidth();
  const onMobile = currentSize < 640;

  const activelySelected = showPopUp?.active === "monthly";

  const onClick = useCallback(() => {
    if (activelySelected) {
      handlePopUp(null);
    } else {
      sendGAEvent({
        action: "infoIconClick",
        context: "Reach Insights",
        element: "Monthly Listens",
        page: "Podcast Insights",
        podcastId,
      });
      handlePopUp("monthly");
    }
  }, [activelySelected, handlePopUp, podcastId]);

  const wrapperRef = useOutsideClickHandler(() => handlePopUp(null));

  return (
    <InsightCard
      styles={showWarning ? overrideCardStylesWithWarning : overrideCardStyles}
    >
      <ReachContent>
        <div className={css(styles.firstContainer)}>
          <ReachContentIcon icon={faReachAll} dataid="monthlyListeners" />
          <h2 className={css(styles.title)}>Total Monthly Listens</h2>
          <ReachContentHelpIcon
            onClick={onClick}
            show={activelySelected}
            showWarning={showWarning}
          >
            {activelySelected && (
              <ReachPopUp
                styles={
                  showWarning ? ReachPopUpWithWarningStyles : ReachPopUpStyles
                }
              >
                <h2 className={css(styles.popUpTitle)}>
                  Total Monthly Listens
                </h2>
                <p className={css(styles.para)}>
                  {isVerified
                    ? "Approximately how many listens this podcast received across all episodes over the last 30 days, based on reach data independently verified via Podchaser's partner verification program."
                    : "Our estimate of how many listens this podcast received across all episodes over the last 30 days, based on real data from millions of devices. Please contact the podcast for precise listenership."}
                </p>
                {!isVerified && (
                  <>
                    {onMobile ? (
                      <a
                        className={css(styles.paraBold)}
                        href="https://support.podchaser.com/en/articles/8672076-demographics-and-reach"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more &nbsp;&nbsp;
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          dataid="expand"
                        />
                      </a>
                    ) : (
                      <p className={css(styles.para)}>
                        Learn more about
                        <a
                          ref={wrapperRef}
                          className={css(styles.link)}
                          href="https://support.podchaser.com/en/articles/8672076-demographics-and-reach"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Total Monthly Listens
                        </a>
                      </p>
                    )}
                  </>
                )}
                {onMobile && (
                  <button onClick={onClick} className={css(styles.button)}>
                    Got It
                  </button>
                )}
              </ReachPopUp>
            )}
          </ReachContentHelpIcon>
        </div>
        <BasicTooltip
          renderTooltip={() => <span>{"Total Monthly Listens"}</span>}
          placement="top"
          zIndex={9999}
        >
          {(tooltipProps) => (
            <h3 {...tooltipProps} className={css(styles.data)}>
              {reachEstimate || "Under 1K"}
            </h3>
          )}
        </BasicTooltip>
      </ReachContent>
    </InsightCard>
  );
}

MonthlyListeners.propTypes = {
  handlePopUp: PropTypes.func,
  isVerified: PropTypes.bool,
  podcastId: PropTypes.number,
  reachEstimate: PropTypes.string,
  showPopUp: PropTypes.object,
  showWarning: PropTypes.bool,
};

MonthlyListeners.defaultProps = {
  reachEstimate: "-",
};
