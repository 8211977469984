import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const InsightsChartContainer = Loadable(() =>
  import("./InsightChartsContainer")
);

function InsightsChartContainerAsync() {
  return <LoadingOverlay height={"136rem"} />;
}

const InsightsChartContainerContainer = (props) => {
  return (
    <InsightsChartContainer
      {...props}
      fallback={<InsightsChartContainerAsync />}
    />
  );
};

export default InsightsChartContainerContainer;
