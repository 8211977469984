import { Map } from "immutable";
import PropTypes from "prop-types";
import React from "react";

import PoliticalSkew from "./PoliticalSkew";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    display: "grid",
    gridTemplateRows: "max-content 1fr max-content",
    gridGap: "1rem",
    position: "relative",
  },
  title: {
    ...gStyles.avalonBold,
    margin: 0,
    fontSize: "1rem",
    padding: 0,
    marginRight: "1rem",
  },
  cardContainer: {
    position: "relative",
    width: 211,
  },
};

const insightCardStyles = {
  cardContainer: {
    [ScreenSizes.mdAndAbove]: {
      boxShadow: colours.InsightCardBoxShadow,
    },
  },
};

export default function InsightContent(props) {
  const { entity, passedRef, hideTitle } = props;

  const { styles, css } = useStyles(baseStyles, props);

  if (!entity) {
    return null;
  }

  return (
    <div className={css(!hideTitle && styles.container)} ref={passedRef}>
      {!hideTitle && <h2 className={css(styles.title)}>Content</h2>}
      <div className={css(styles.cardContainer)}>
        <PoliticalSkew entity={entity} insightCardStyles={insightCardStyles} />
      </div>
    </div>
  );
}

InsightContent.propTypes = {
  entity: PropTypes.instanceOf(Map),
};

InsightContent.defaultProps = {
  entity: null,
};
