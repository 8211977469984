import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  icon: {
    color: "var(--color-neutral-d4)",
    fontSize: "0.75rem",
  },
};

export default function ReachContentIcon(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { icon, dataid } = props;

  return (
    <FontAwesomeIcon className={css(styles.icon)} icon={icon} dataid={dataid} />
  );
}

ReachContentIcon.propTypes = {
  icon: PropTypes.object,
  dataid: PropTypes.string,
};
