import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useContext, useMemo } from "react";

import { TabsContext } from "components/Common/Tabs";
import Title from "components/Entities/Page/TitleAsync";
import PodcastsStub from "components/Entities/Stubs/PodcastsStub";

import { insightStyles, secondaryTitleStyles } from "../InsightsStyles";

import * as sortConstants from "constants/sort";
import getCategoryText from "utils/entity/getCategoryText";
import getCategoryUrl from "utils/entity/getCategoryUrl";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useList from "hooks/useList";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...insightStyles,
  items: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    marginRight: 0,
    width: "calc(100% + 1rem)",
  },
  item: {
    marginBottom: "1rem",
    width: "calc(50% - 1rem)",
    marginRight: "1rem",

    [ScreenSizes.mdAndAbove]: {
      width: "calc(33.33333% - 1rem)",
      marginRight: "1rem",
    },
    [ScreenSizes.xlAndAbove]: {
      width: "calc(16.66667% - 1rem)",
      marginRight: "1rem",
    },
  },
};

const firstTitleStyes = {
  titleContainer: {
    paddingTop: 0,

    [ScreenSizes.lgAndAbove]: {
      paddingTop: 0,
    },
  },
};

const titleStyles = {
  titleContainer: {
    paddingTop: "3rem",
  },
};

const newStyles = {
  ...secondaryTitleStyles,
  titleContainer: {
    ...secondaryTitleStyles.titleContainer,
    marginLeft: "1rem",

    [ScreenSizes.lgAndAbove]: {
      ...secondaryTitleStyles.titleContainer,
      marginLeft: "0",
    },
  },
};

const InsightsSimilar = (props) => {
  const { podcast, passedRef } = props;
  const { styles } = useStyles(baseStyles, props);

  // Section - General Similar
  const similarStaticFilters = useMemo(
    () => ({
      recommendations: {
        value: {
          podcasts: [podcast.get("id")],
        },
      },
      exclude: {
        value: [podcast.get("id")],
      },
    }),
    [podcast]
  );
  const similarListKey = `podcastView/${podcast.get("id")}/insightsSimilar`;
  const { total: similarTotal } = useList(similarListKey);
  const { contentRef: tabContentRef } = useContext(TabsContext);
  const { isWindowSizeOrLess } = useWindowSize();

  const mobile = isWindowSizeOrLess("medium");

  // Section - Similar by first category
  const similarCategory = podcast.get("categories").get(0);
  const similarCategoryName = getCategoryText(similarCategory, {
    singular: true,
  });
  const similarCategoryStaticFilters = useMemo(
    () => ({
      category: {
        value: similarCategory,
      },
      exclude: {
        value: [podcast.get("id")],
      },
    }),
    [podcast, similarCategory]
  );
  const similarCategoryListKey = `podcastView/${podcast.get(
    "id"
  )}/insightsSimilarCategory`;
  const { total: similarCategoryTotal } = useList(similarCategoryListKey);

  // Section - Similar by first category
  const similarSecondaryCategory = podcast.get("categories").get(1);
  const similarSecondaryCategoryStaticFilters = useMemo(
    () => ({
      category: {
        value: similarSecondaryCategory,
      },
      exclude: {
        value: [podcast.get("id")],
      },
    }),
    [podcast, similarSecondaryCategory]
  );
  const similarSecondaryCategoryListKey = `podcastView/${podcast.get(
    "id"
  )}/insightsSimilarSecondaryCategory`;
  const { total: similarSecondaryCategoryTotal } = useList(
    similarSecondaryCategoryListKey
  );
  const similarSecondaryCategoryName = getCategoryText(
    similarSecondaryCategory,
    { singular: true }
  );

  const hasSimilarResults =
    similarTotal > 0 ||
    similarCategoryTotal > 0 ||
    similarSecondaryCategoryTotal > 0;

  const renderStub = (stubProps) => (
    <PodcastsStub
      entity_type="podcast"
      entity={podcast}
      scrollRef={tabContentRef}
      showTitles={false}
      showBadges={true}
      showViewAll={false}
      clickEventAction="insightsSimilarPodcastClick"
      clickEventVars={{
        from_podcast: podcast.get("id"),
        ...stubProps.analyticsVariables,
      }}
      maxItems={18}
      titleStyles={titleStyles}
      dontRenderList={!hasSimilarResults}
      hideEmpty
      inSidebar={mobile}
      {...stubProps}
    />
  );

  const podcastStubs = (
    <div ref={passedRef}>
      {renderStub({
        listKey: similarListKey,
        title: `Similar To ${podcast.get("title")}`,
        staticFilters: similarStaticFilters,
        maxItems: 24,
        titleStyles: firstTitleStyes,
        viewAllTitle: `View more podcasts like ${podcast.get("title")}`,
        viewAllLink: getPodcastUrl(podcast, "similar"),
        dontRenderList: similarTotal === 0,
        analyticsVariables: {
          type: "similar",
        },
        sort: sortConstants.SORT_ORDER_POPULAR,
      })}

      {similarCategory &&
        renderStub({
          listKey: similarCategoryListKey,
          title: `Other ${similarCategoryName} Podcasts`,
          staticFilters: similarCategoryStaticFilters,
          rescore: { sort: sortConstants.SORT_ORDER_RANDOM, window: 100 },
          viewAllLink: getCategoryUrl(similarCategory),
          viewAllTitle: `View More ${similarCategoryName} Podcasts`,
          dontRenderList: similarCategoryTotal === 0,
          titleStyles: similarTotal === 0 ? firstTitleStyes : null,
          sort: sortConstants.SORT_ORDER_RANKING,
          analyticsVariables: {
            type: "category",
            category: similarCategoryName,
          },
        })}

      {similarSecondaryCategory &&
        renderStub({
          listKey: similarSecondaryCategoryListKey,
          title: `Other ${similarSecondaryCategoryName} Podcasts`,
          staticFilters: similarSecondaryCategoryStaticFilters,
          rescore: { sort: sortConstants.SORT_ORDER_RANDOM, window: 100 },
          viewAllLink: getCategoryUrl(similarSecondaryCategory),
          viewAllTitle: `View More ${similarSecondaryCategoryName} Podcasts`,
          dontRenderList: similarSecondaryCategoryTotal === 0,
          titleStyles:
            similarTotal + similarCategoryTotal === 0 ? firstTitleStyes : null,
          sort: sortConstants.SORT_ORDER_RANKING,
          analyticsVariables: {
            type: "secondary_category",
            category: similarSecondaryCategoryName,
          },
        })}
    </div>
  );

  if (hasSimilarResults) {
    return (
      <section className={css(styles.insightsSection)} ref={passedRef}>
        <Title
          title="Similar Podcasts"
          TitleComponent="h2"
          styles={newStyles}
          noTopPadding
        />
        <div className={css(styles.insightsContent)}>{podcastStubs}</div>
      </section>
    );
  }

  return podcastStubs;
};

InsightsSimilar.propTypes = {
  podcast: PropTypes.instanceOf(Map),
};
InsightsSimilar.defaultProps = {
  podcast: null,
};

export default InsightsSimilar;
