import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons/faCheckSquare";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";

import formatFieldDate from "./formatFieldDate";

import colours from "styles/colours";

export default function getPodcastStatusDetails(podcast) {
  // , hasPro = false) => {
  if (!podcast) {
    return null;
  }

  const last_air_date = formatFieldDate(podcast, "date_of_latest_episode");

  let code;
  let status;
  let title;
  let shortTitle;
  let icon;
  let iconColor;
  let proImportant = false;
  let conciergeMessage = null;

  switch (podcast.get("podcast_status")) {
    case "active":
      code = "active";
      title = "Podcast is still active";
      shortTitle = "Active";
      status = `This podcast has released episodes recently (${last_air_date}) and still appears to be active.`;
      icon = faCheckSquare;
      iconColor = colours.positive;
      break;
    case "idle":
    default:
      code = "idle";
      title = "Podcast may be on a hiatus or finished";
      shortTitle = "Idle";
      status = `This podcast hasn't released an episode since ${last_air_date}.`;
      icon = faExclamationTriangle;
      iconColor = colours.inProgress;
      proImportant = true;
      conciergeMessage =
        "NOTICE: You are requesting information for a show " +
        "that doesn't seem to be releasing episodes. Demographics, audience " +
        "size, and contacts may not exist.";
      break;
  }
  return {
    code,
    title,
    shortTitle,
    status,
    icon,
    iconColor,
    proImportant,
    conciergeMessage,
  };
}

export const getPodcastStatus = (podcast, userHasPro = false) => {
  const details = getPodcastStatusDetails(podcast, userHasPro);
  return details && details.shortTitle;
};
