import { faArrowUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import InsightCard from "../../../components/InsightCard";
import ReachContent, { firstContainerStyles } from "../ReachContent";
import ReachContentHelpIcon from "../ReachContentHelpIcon";
import ReachContentIcon from "../ReachContentIcon";
import ReachPopUp from "../ReachPopUp";

import sendGAEvent from "utils/sendGAEvent";

import useOutsideClickHandler from "hooks/useOutsideClickHandler";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import faReachSingle from "styles/icons/faReachSingle";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  ...firstContainerStyles,
  data: {
    ...gStyles.avalonBold,
    fontSize: "1.5rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  noAvailable: {
    fontSize: "1rem",
    color: colours.newDarkGrey,
    textAlign: "center",
    margin: 0,
    cursor: "pointer",
  },
  noAvailableHover: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
  },
  noAvailableContainer: {
    position: "relative",
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    color: "var(--color-neutral-d5)",
    textAlign: "center",
    margin: 0,
  },
  popUpTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  para: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  paraBold: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: 0,
  },
  link: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.primary,
    textAlign: "center",
    margin: "0 0 0 0.188rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  button: {
    width: "max-content",
    border: `1px solid ${colours.borderGrey}`,
    padding: "0.5rem 1rem",
    borderRadius: 25,
    ...gStyles.avalonBold,
    fontSize: "1rem",
    color: colours.bodyText,
    textAlign: "center",
    margin: "1rem auto 0 auto",
  },
};

const ReachPopUpStyles = {
  outer: {
    marginLeft: 0,

    [ScreenSizes.mdAndAbove]: {
      marginLeft: -260,
      bottom: 30,
    },
  },
  triangle: {
    right: -10,
    left: "auto",
  },
};

const ReachPopUpWithWarningStyles = {
  outer: {
    marginLeft: 0,

    [ScreenSizes.mdAndAbove]: {
      marginTop: -260,
      marginLeft: -238,
    },
    [createBreakpoint({ min: 920 })]: {
      marginLeft: -240,
    },
  },
  triangle: {
    right: -10,
    left: "auto",
  },
};

const NoDataReachPopUpStyles = {
  outer: {
    marginLeft: 0,

    [ScreenSizes.mdAndAbove]: {
      marginLeft: -90,
      bottom: 30,
    },
  },
  container: {
    display: "flex",
  },
};

const overrideCardStyles = {
  cardContainer: {
    borderRadius: 0,
    borderTop: "none",

    [createBreakpoint({ min: 920 })]: {
      borderTopRightRadius: 12,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTop: `1px solid var(--color-neutral-d95)`,
    },
  },
};

const overrideCardStylesWithWarning = {
  cardContainer: {
    borderRadius: 0,
    borderTop: "none",

    [ScreenSizes.mdAndAbove]: {
      borderBottomRightRadius: 12,
    },

    [createBreakpoint({ min: 920 })]: {
      borderTopRightRadius: 12,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTop: `1px solid var(--color-neutral-d95)`,
      borderBottom: `1px solid var(--color-neutral-d95)`,
    },
  },
};

const REACH_ARTICLE =
  "https://support.podchaser.com/en/articles/8672076-demographics-and-reach";

export default function EpisodesListeners(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const {
    averageEstimatedEpisodeReach,
    showPopUp,
    handlePopUp,
    showWarning,
    entityId,
    isVerified,
    insightCardStyles,
    label,
    description: passedDescription,
  } = props;

  const [showNoDataAvailable, setShowNoDataAvailable] = useState(false);

  const { getWindowWidth } = useWindowSize();

  const currentSize = getWindowWidth();
  const onMobile = currentSize < 640;

  const activelySelected = showPopUp?.active === "episodes";

  const onClick = useCallback(() => {
    if (activelySelected) {
      handlePopUp(null);
    } else {
      sendGAEvent({
        action: "infoIconClick",
        context: "Reach Insights",
        element: "New Episods Listens",
        page: "Podcast Insights",
        entityId,
      });
      handlePopUp("episodes");
    }
  }, [activelySelected, handlePopUp, entityId]);

  const handleClickNoDataTitle = useCallback(() => {
    if (activelySelected) {
      setShowNoDataAvailable(false);
    } else {
      sendGAEvent({
        action: "infoIconClick",
        context: "Reach Insights",
        element: "New Episods Listens No Data",
        page: "Podcast Insights",
        entityId,
      });
      setShowNoDataAvailable(true);
    }
  }, [activelySelected, entityId]);

  const wrapperRef = useOutsideClickHandler(() => handlePopUp(null));
  const noDataClickHere = useOutsideClickHandler(() =>
    setShowNoDataAvailable(false)
  );

  const defaultDescription = isVerified
    ? "Approximately how many listens a new episode of this podcast receives within its first 30 days, based on reach data independently verified via Podchaser's partner verification program."
    : "Our estimate of how many listens a new episode of this podcast will receive within its first 30 days, based on real data from millions of devices. Please contact the podcast for precise listenership.";

  const description = passedDescription || defaultDescription;

  return (
    <InsightCard
      styles={[
        showWarning ? overrideCardStylesWithWarning : overrideCardStyles,
        insightCardStyles,
      ]}
    >
      <ReachContent>
        <div className={css(styles.firstContainer)}>
          <ReachContentIcon icon={faReachSingle} dataid="episodesListeners" />
          <h2 className={css(styles.title)}>{label}</h2>
          <ReachContentHelpIcon
            onClick={onClick}
            show={activelySelected}
            showWarning={showWarning}
          >
            {activelySelected && (
              <ReachPopUp
                styles={
                  showWarning ? ReachPopUpWithWarningStyles : ReachPopUpStyles
                }
              >
                <h2 className={css(styles.popUpTitle)}>{label}</h2>
                <p className={css(styles.para)}>{description} </p>
                {!isVerified && (
                  <>
                    {onMobile ? (
                      <a
                        className={css(styles.paraBold)}
                        href={REACH_ARTICLE}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more &nbsp;&nbsp;
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          dataid="expand"
                        />
                      </a>
                    ) : (
                      <p className={css(styles.para)}>
                        Learn more about
                        <a
                          ref={wrapperRef}
                          className={css(styles.link)}
                          href={REACH_ARTICLE}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {label}
                        </a>
                      </p>
                    )}
                  </>
                )}
                {onMobile && (
                  <button onClick={onClick} className={css(styles.button)}>
                    Got It
                  </button>
                )}
              </ReachPopUp>
            )}
          </ReachContentHelpIcon>
        </div>

        {averageEstimatedEpisodeReach === null ? (
          <div className={css(styles.noAvailableContainer)}>
            <h3
              className={css(styles.noAvailable)}
              onClick={handleClickNoDataTitle}
            >
              Not Available
            </h3>
            {showNoDataAvailable && (
              <ReachPopUp styles={NoDataReachPopUpStyles}>
                <p className={css(styles.noAvailableHover)}>
                  This data is currently unavailable.{" "}
                  <a
                    ref={noDataClickHere}
                    className={css(styles.link)}
                    href={REACH_ARTICLE}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click here for more information.
                  </a>
                </p>
              </ReachPopUp>
            )}
          </div>
        ) : (
          <BasicTooltip
            renderTooltip={() => <span>{label}</span>}
            placement="top"
            zIndex={9999}
          >
            {(tooltipProps) => (
              <h3 {...tooltipProps} className={css(styles.data)}>
                {averageEstimatedEpisodeReach}
              </h3>
            )}
          </BasicTooltip>
        )}
      </ReachContent>
    </InsightCard>
  );
}

EpisodesListeners.propTypes = {
  averageEstimatedEpisodeReach: PropTypes.string,
};

EpisodesListeners.defaultProps = {
  averageEstimatedEpisodeReach: "-",
  label: "New Episode Listens",
};
