import { faDownload } from "@fortawesome/pro-solid-svg-icons/faDownload";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useCallback, useRef, useState, useContext } from "react";
import { downloadInsightsPdfPromise } from "routines/insightsPdf";

import StandardButton from "components/Buttons/StandardButton";
import RequestContext from "pages/RequestContext";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import usePodcastData from "hooks/usePodcastData";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  downloadIcon: {
    marginLeft: "0.5rem",
    lineHeight: "1em",
    pointerEvents: "none",
  },
  hiddenDownloadLink: {
    maxWidth: 0,
    maxHeight: 0,
    overflow: "hidden",
    cursor: "none",
    display: "none",
  },
  lockIcon: {
    marginLeft: "1rem",
    lineHeight: "1em",
    pointerEvents: "none",
  },
  internalOnly: {
    ...gStyles.avalonBold,
    color: colours.white,

    fontSize: ".65rem",
    position: "absolute",
    top: "0.35rem",
    right: "3rem",

    [ScreenSizes.mdAndAbove]: {
      right: "1.4rem",
      color: colours.lightishGrey,
    },
  },
};

const buttonStyles = {
  button: {
    ...gStyles.avalonBold,
    position: "relative",
  },
};

export default function ExportButton(props) {
  const { podcastId, variation } = props;
  const { styles, css } = useStyles(baseStyles, props);

  const podcast = usePodcastData(podcastId);
  const downloadRef = useRef(null);
  const [csvLink, setCsvLink] = useState(null);
  const [loading, setLoading] = useState(false);

  const userHasPro = useUserHasPro();
  const requestContext = useContext(RequestContext);

  const isChrome =
    requestContext.userAgent && requestContext.userAgent.indexOf("Chrome") > -1;

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const { downloadInsightsPdf } = useRoutinePromises({
    downloadInsightsPdf: downloadInsightsPdfPromise,
  });

  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      action: "submittedProLeadFormModal",
      page: "Podcast Insights View",
      context: "ProLeadForm",
    });
  }, []);

  const handleExportPodcastClick = useCallback(() => {
    sendGAEvent({
      action: "clickExportButton",
      page: "Podcast Insights View",
      podcast_id: podcast?.get("id"),
      podcast_title: podcast?.get("title"),
      userHasPro,
    });
    if (!userHasPro) {
      sendGAEvent({
        action: "openedProLeadFormModal",
        page: "Podcast Insights View",
        podcast_id: podcast?.get("id"),
        podcast_title: podcast?.get("title"),
        userHasPro,
      });

      showModal("proLeadForm", {
        onDemoRequestSubmitted,
      });
    }

    if (userHasPro) {
      setLoading(true);
      downloadInsightsPdf({ podcastId })
        .then(({ response }) => {
          setCsvLink(response.pdfLink);
          downloadRef.current.click();
          setLoading(false);

          showModal("insightsExport", {
            pdfLink: response.pdfLink,
            numberDownloadsLeft: response.limitInsightsExportPdf,
            podcastId,
          });
        })
        .catch((error) => {
          console.log("error", error);
          setLoading(false);

          showModal("insightsExportLimit", { podcastId });
        });
    }
  }, [
    downloadInsightsPdf,
    podcast,
    podcastId,
    showModal,
    userHasPro,
    onDemoRequestSubmitted,
  ]);

  return (
    <>
      <StandardButton
        variation={variation || "white"}
        fontSize=".75rem"
        flat
        customStyles={buttonStyles}
        key="ExportButton"
        onClick={handleExportPodcastClick}
        submitting={loading}
      >
        Export One-Sheet
        {userHasPro ? (
          <>
            <span className={css(styles.downloadIcon)}>
              <FontAwesomeIcon icon={faDownload} />
            </span>
          </>
        ) : (
          <span className={css(styles.lockIcon)}>
            <FontAwesomeIcon icon={faLock} />
          </span>
        )}
      </StandardButton>
      <a
        className={css(styles.hiddenDownloadLink)}
        ref={downloadRef}
        href={csvLink}
        rel={isChrome ? undefined : "noopener noreferrer"}
        target={isChrome ? undefined : "_blank"}
      />
    </>
  );
}

ExportButton.propTypes = {
  podcastId: PropTypes.number,
  variation: PropTypes.string,
};
