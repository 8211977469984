export const getAuthorityColorRgb = (authorityLevel) => {
  const emptyIconBackground = [161, 167, 181];
  const fullIconBackground = [217, 17, 95];
  const value = Math.max(0, Math.min(100, authorityLevel)) / 100;

  const rgb = {
    red: Math.floor(
      emptyIconBackground[0] +
        value * (fullIconBackground[0] - emptyIconBackground[0])
    ),
    green: Math.floor(
      emptyIconBackground[1] +
        value * (fullIconBackground[1] - emptyIconBackground[1])
    ),
    blue: Math.floor(
      emptyIconBackground[2] +
        value * (fullIconBackground[2] - emptyIconBackground[2])
    ),
  };

  return `rgb(${rgb.red}, ${rgb.green}, ${rgb.blue})`;
};

export const getAuthorityForegroundColor = (authorityLevel) => {
  const authorityColors = [
    { min: 91, max: 100, color: [217, 15, 95] },
    { min: 77, max: 90, color: [195, 47, 110] },
    { min: 57, max: 76, color: [173, 78, 124] },
    { min: 33, max: 56, color: [154, 110, 141] },
    { min: 0, max: 32, color: [132, 141, 157] },
  ];

  const matchedAuthColor = authorityColors.find(
    (authorityColor) =>
      authorityLevel >= authorityColor.min &&
      authorityLevel <= authorityColor.max
  );

  return matchedAuthColor ? `rgb(${matchedAuthColor.color.join(", ")})` : "";
};

export const getAuthorityBackgroundColour = (authorityLevel) => {
  const authorityColors = [
    { min: 91, max: 100, color: [254, 184, 211] },
    { min: 77, max: 90, color: [252, 197, 220] },
    { min: 57, max: 76, color: [247, 214, 230] },
    { min: 33, max: 56, color: [245, 228, 239] },
    { min: 0, max: 32, color: [240, 243, 248] },
  ];

  const matchedAuthColor = authorityColors.find(
    (authorityColor) =>
      authorityLevel >= authorityColor.min &&
      authorityLevel <= authorityColor.max
  );

  return matchedAuthColor ? `rgb(${matchedAuthColor.color.join(", ")})` : "";
};
