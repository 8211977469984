import { userHasViewBrandSafetyPermission } from "utils/entity/user";

import useLoggedInUser from "hooks/useLoggedInUser";

const useUserHasViewBrandSafetyPermission = () => {
  const user = useLoggedInUser();

  return userHasViewBrandSafetyPermission(user);
};

export default useUserHasViewBrandSafetyPermission;
