import Loadable from "@loadable/component";
import { css } from "aphrodite";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "41.5rem",

    [ScreenSizes.mdAndAbove]: {
      height: "41.5rem",
    },
  },
};

const InsightAudience = Loadable(() => import("./InsightAudience"));

function InsightAudienceAsyncLoading(props) {
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.container)} />;
}

const InsightAudienceContainer = (props) => {
  return (
    <InsightAudience {...props} fallback={<InsightAudienceAsyncLoading />} />
  );
};

export default InsightAudienceContainer;
