import { faExternalLink } from "@fortawesome/pro-solid-svg-icons/faExternalLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import Dotdotdot from "components/Common/Dotdotdot";
import EntityImage from "components/Entities/EntityImage";

import CardTitle from "../CardTitle";
import NetworkSummary from "./NetworkSummary";

import { useStyles } from "hooks/useStyles";
import userHasInternalPermission from "hooks/useUserHasInternalPermission";

import cardStyles, { noBorderCardStyles } from "styles/CardStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  networkMobileCard: {
    ...cardStyles.mobileCard,
  },
  topRow: {
    ...cardStyles.mobileCardTopRow,
    alignItems: "top",

    [ScreenSizes.xsAndBelow]: {
      flexDirection: "column",
    },
  },
  networkImage: {
    ...cardStyles.mobileCardEntityImage,

    [ScreenSizes.xsAndBelow]: {
      ...cardStyles.mobileCardEntityImage[ScreenSizes.xsAndBelow],
      width: 160,
      height: 160,
      margin: "0 auto 1rem",
      background: "#f9f9f9",
    },
    alignSelf: "flex-start",
  },
  networkImageFullWidth: {
    maxWidth: "none",
  },
  networkInfo: {
    ...cardStyles.mobileCardEntityInfo,

    [ScreenSizes.xsAndBelow]: {
      ...cardStyles.mobileCardEntityInfo[ScreenSizes.xsAndBelow],
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
  },
  networkTitle: {
    fontSize: "1.25rem",
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1em",
    },
  },
  descriptionRow: {
    lineHeight: 1.4,
  },
  externalLinkIcon: {
    fontSize: "0.5em",
    marginLeft: "1em",
    color: "#777",
    verticalAlign: "middle",
  },
};

const noPanelStyles = {
  networkMobileCard: {
    ...cardStyles.cardNoPanel,
  },
};

const noBorderStyles = {
  ...noBorderCardStyles,
  networkMobileCard: {
    ...noBorderCardStyles.card,
  },
};

const cardTitleStyles = {
  title: {
    [ScreenSizes.mdAndBelow]: {
      fontSize: "1.25rem",
    },
  },
};

const NetworkMobileCard = (props) => {
  const { network, noPanel, fullWidth, noBorder, renderOnMount } = props;
  const userIsInternal = userHasInternalPermission();

  const { styles } = useStyles(
    [baseStyles, noPanel && noPanelStyles, noBorder && noBorderStyles],
    props
  );

  const entityLineProps = useMemo(
    () => ({
      additionalContent: network.get("url") && !userIsInternal && (
        <span className={css(styles.externalLinkIcon)}>
          <FontAwesomeIcon icon={faExternalLink} />
        </span>
      ),
    }),
    [network, styles.externalLinkIcon, userIsInternal]
  );

  const renderDescription = () => (
    <Dotdotdot clamp={4} className={css(styles.descriptionRow)} tagName="div">
      {network.get("description") || <NetworkSummary {...props} />}
    </Dotdotdot>
  );

  const renderTopRow = () => (
    <div className={css(styles.topRow)}>
      <EntityImage
        className={css(
          styles.networkImage,
          fullWidth && styles.networkImageFullWidth
        )}
        entity_type="network"
        entity={network}
        fullWidth={false}
      />
      <div className={css(styles.networkInfo)}>
        <CardTitle
          className={css(styles.networkTitle)}
          entity={network}
          entityType="network"
          hideSecondaryButtons
          styles={cardTitleStyles}
          entityLineProps={entityLineProps}
          renderOnMount={renderOnMount}
        />
        {renderDescription()}
      </div>
    </div>
  );

  return (
    <div
      data-id="network-mobile-card"
      className={css(styles.networkMobileCard, styles.entityCard)}
    >
      {renderTopRow()}
    </div>
  );
};

NetworkMobileCard.propTypes = {
  network: PropTypes.instanceOf(Map),
  noPanel: PropTypes.bool,
  insidePanel: PropTypes.bool,
  fullWidth: PropTypes.bool,
  noBorder: PropTypes.bool,
  renderOnMount: PropTypes.bool,
};

NetworkMobileCard.defaultProps = {
  network: null,
  noPanel: false,
  insidePanel: false,
  fullWidth: false,
  noBorder: false,
  renderOnMount: false,
};

export default NetworkMobileCard;
