import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { formatDistance } from "date-fns/formatDistance";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import Title from "components/Entities/Page/Title";
import NoResultsMessage from "pages/Common/NoResultsMessage";

import { insightStyles, secondaryTitleStyles } from "../InsightsStyles";

import getPeriodFromDays from "utils/date/getPeriodFromDays";
import { podcastExclusiveTo } from "utils/entity/exclusiveTo";
import formatFieldDate from "utils/entity/formatFieldDate";
import generateItunesUrl from "utils/entity/generateItunesUrl";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import getNetworkUrl, {
  getNetworkUrlByEntity,
} from "utils/entity/getNetworkUrl";
import getPodcastHasGuests from "utils/entity/getPodcastHasGuests";
import getPodcastStatusDetails, {
  getPodcastStatus,
} from "utils/entity/getPodcastStatusDetails";
import { secondsToDaysHoursMinSecsChars } from "utils/formatTime";
import { capitalize } from "utils/misc";
import { extractSimpleDomain } from "utils/url";

import useHasProForPodcast from "hooks/useHasProForPodcast";
import { useStyles } from "hooks/useStyles";
import userHasInternalPermission from "hooks/useUserHasInternalPermission";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...insightStyles,
  items: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    marginBottom: 0, // "-1rem",
  },
  item: {
    marginBottom: "1rem",
    flex: "0 0 50%",

    [ScreenSizes.mdAndAbove]: {
      flex: "0 0 50%",
    },
    [ScreenSizes.xlAndAbove]: {
      flex: "0 0 25%",
      marginBottom: "2rem",
    },
  },
  lastRowItem: {
    marginBottom: 0,

    [ScreenSizes.mdAndAbove]: {
      marginBottom: 0,
    },
    [ScreenSizes.xlAndAbove]: {
      marginBottom: 0,
    },
  },
  itemLabel: {
    ...gStyles.avalonBold,
    margin: "0 0 0.5rem",
    fontSize: "1rem",
  },
  itemValue: {
    display: "block",
    margin: "1rem 0",
  },
  itemFormat: {
    margin: 0,
    fontSize: "0.8125rem",
  },
  itemLink: {
    color: colours.primary,

    ":hover": {
      textDecoration: "underline",
    },
    ":focus": {
      textDecoration: "underline",
    },
  },
  podcastStatusContent: {
    display: "flex",
    alignItems: "center",
  },
  podcastStatusDescription: {
    ...gStyles.resetButton,
    color: colours.greyishBlue,
    cursor: "pointer",
    fontSize: ".875rem",
    marginLeft: "0.5rem",
    marginRight: "1.25em",

    ":hover": {
      color: colours.greyishBlueDarker,
      outline: "none",
    },
    ":focus": {
      color: colours.greyishBlueDarker,
      outline: "none",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: ".75rem",
      marginRight: "2em",
    },
  },
};

const InsightsDetails = (props) => {
  const { podcast, showAmount, passedRef } = props;
  const { styles } = useStyles(baseStyles, props);

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("large");

  const [showAdditional] = useState(false);

  const userHasPro = useHasProForPodcast(podcast && podcast.get("id"));
  const userIsInternal = userHasInternalPermission();

  const renderPodcastStatus = useCallback(
    (pod) => {
      const details = getPodcastStatusDetails(pod, userHasPro);

      if (details) {
        return (
          <div className={css(styles.podcastStatusContent)}>
            {details.shortTitle}

            <BasicTooltip renderTooltip={() => details.status} zIndex={99999}>
              {(tooltipProps) => (
                <button
                  type="button"
                  data-id="podcast-status-description"
                  className={css(styles.podcastStatusDescription)}
                  {...tooltipProps}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </button>
              )}
            </BasicTooltip>
          </div>
        );
      }

      return null;
    },
    [styles.podcastStatusContent, styles.podcastStatusDescription, userHasPro]
  );

  const items = useMemo(
    () => [
      // TODO: Consider sharing with Stats or StatsPanel, but keep in mind the values and layout may change
      {
        title: "Podcast Status",
        format: (pod, userHasPro) => getPodcastStatus(pod, userHasPro),
        condition: (pod, userHasPro) =>
          !!getPodcastStatusDetails(pod, userHasPro),
        renderContent: renderPodcastStatus,
      },
      {
        title: "Release Period",
        format: (pod) => {
          if (
            pod.get("days_between_episodes") &&
            pod.get("days_between_episodes") > 0
          ) {
            return getPeriodFromDays(pod.get("days_between_episodes"), {
              exactOnly: false,
            });
          }

          return null;
        },
      },
      {
        title: "Explicit",
        format: (pod) => {
          if (!pod.has("is_explicit")) {
            return null;
          }

          return pod.get("is_explicit") ? "Yes" : "No";
        },
      },
      {
        title: "First Episode",
        format: (pod) => formatFieldDate(pod, "date_of_first_episode"),
      },
      {
        title: "Latest Episode",
        metaTitle: (pod) =>
          `Episode: ${pod.getIn(["latest_episode", "title"])}`,
        format: (pod) => formatFieldDate(pod, "date_of_latest_episode"),
        to: (pod) =>
          pod.get("latest_episode") &&
          getEpisodeUrl(pod.get("latest_episode"), { podcast: pod }),
      },
      {
        title: "Episode Length",
        format: (pod) => {
          if (pod.get("average_episode_length_seconds")) {
            const avgLength = formatDistance(
              pod.get("average_episode_length_seconds") * 1000,
              0
            );

            return capitalize(avgLength);
          }

          return null;
        },
      },
      {
        title: "Language",
        format: (pod) => pod.getIn(["language", "name"]),
      },
      {
        title: "Seasons",
        format: (podcast) => {
          const numberOfSeasons = podcast.get("number_of_seasons");

          if (numberOfSeasons > 0) {
            return numberOfSeasons;
          }

          return null;
        },
      },
      {
        title: "Episodes",
        format: (pod) => pod.get("number_of_episodes"),
      },
      /* {
      title: "Production Location",
      format: "USA (multiple)",
    },
    {
      title: "Genres",
      format: "Comedy, Advice",
    },
    {
      title: "Format",
      format: "Discussion",
    },*/
      {
        title: "Order",
        format: (pod) => capitalize(pod.get("itunes_type")) || "Unknown",
        condition: (pod) => !!pod.get("itunes_type"),
      },
      {
        title: "Hosting Provider",
        format: (pod) => pod.get("hosting_provider") || "Unknown",
      },
      /* {
      title: "Branded",
      format: "No",
    },*/
      {
        title: "iTunes ID",
        format: (pod) => {
          if (pod.get("itunes_id")) {
            return pod.get("itunes_id");
          }

          return "Unknown";
        },
        to: (pod) =>
          pod.get("itunes_id") && generateItunesUrl(pod.get("itunes_id")),
        external: true,
      },
      {
        title: "Total Runtime",
        format: (pod) =>
          secondsToDaysHoursMinSecsChars(pod.get("total_runtime")) || "Unknown",
      },
      {
        // keep as last field
        title: "Created by",
        format: (pod) => pod.get("itunes_owner_name"),
      },
      {
        title: "Exclusive To",
        format: () => "Spotify",
        condition: (pod) => podcastExclusiveTo(pod, "spotify"),
      },
      {
        // keep as last field
        title: "Official Website",
        format: (pod) =>
          pod.get("web_url") && extractSimpleDomain(pod.get("web_url")),
        to: (pod) => pod.get("web_url"),
        condition: (pod) => !!pod.get("web_url"),
        external: true,
      },
      {
        // keep as last field
        title: "Has Guests",
        format: (pod) =>
          ({ true: "Yes", false: "Unlikely", never: "Never" }[
            getPodcastHasGuests(pod)
          ]),
      },
      {
        title: "Network",
        to: (pod) => {
          const network = pod?.get("networks")?.first();

          let networkUrl = getNetworkUrl(network);
          if (userIsInternal) {
            networkUrl = getNetworkUrlByEntity(network);
          }

          return networkUrl;
        },
        external: !userIsInternal,
        format: (pod) => {
          const networkName =
            pod?.get("networks") && pod?.get("networks")?.first()?.get("title");

          return networkName;
        },
      },
    ],
    [renderPodcastStatus, userIsInternal]
  );

  if (!podcast) {
    return null;
  }

  const validItems = items.filter(
    (item) =>
      (!item.condition || item.condition(podcast)) &&
      (item.value || (item.format && item.format(podcast, userHasPro)))
  );

  const initialItems = validItems.slice(0, showAmount);
  const hiddenItems = validItems.slice(showAmount);

  const renderItem = (item, index, allItems) => {
    const value = item.format && item.format(podcast, userHasPro);

    if (!value) {
      return null;
    }

    let renderedItem = value;

    if (item.renderContent) {
      renderedItem = item.renderContent(podcast, userHasPro);
    } else if (item.to && item.external) {
      renderedItem = (
        <a
          className={css(styles.itemLink)}
          href={item.to(podcast)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {value}
        </a>
      );
    } else if (item.to) {
      const toLink = item.to(podcast);
      if (toLink) {
        renderedItem = (
          <Link className={css(styles.itemLink)} to={toLink}>
            {value}
          </Link>
        );
      }
    }

    return (
      <li
        key={item.key || item.title}
        className={css(
          styles.item,
          index > allItems.length - (mobile ? 3 : 5) && styles.lastRowItem
        )}
      >
        <h3 className={css(styles.itemLabel)}>{item.title}</h3>
        <span className={css(styles.itemValue)}>{renderedItem}</span>
      </li>
    );
  };

  const renderInitialItem = (item, index) =>
    renderItem(item, index, initialItems);

  const renderHiddenItem = (item, index) =>
    renderItem(item, index, hiddenItems);

  const renderContent = () => (
    <div className={css(styles.insightsContent)}>
      <ul className={css(styles.items)}>
        {initialItems.map(renderInitialItem)}
      </ul>
      {showAdditional && hiddenItems && (
        <ul className={css(styles.items)}>
          {hiddenItems.map(renderHiddenItem)}
        </ul>
      )}
    </div>
  );

  // TODO: Might be able to use InfoMessage once merged?
  const renderBlank = () => (
    <NoResultsMessage
      entity={podcast}
      entityType={"podcast"}
      label="podcast details"
    />
  );

  return (
    <section className={css(styles.insightsSection)} ref={passedRef}>
      <Title
        title="Podcast Details"
        TitleComponent="h2"
        styles={secondaryTitleStyles}
        noTopPadding
      />
      {(initialItems && initialItems.length > 0) ||
      (showAdditional && hiddenItems && hiddenItems.length > 0)
        ? renderContent()
        : renderBlank()}
    </section>
  );
};

InsightsDetails.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  showAmount: PropTypes.number,
};
InsightsDetails.defaultProps = {
  podcast: null,
  showAmount: 20,
};

export default InsightsDetails;
