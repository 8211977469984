import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback } from "react";

import cleanEntityType from "../../utils/entity/cleanEntityType";
import { insightStyles } from "../PodcastView/Insights/InsightsStyles";

import modalActions from "actions/modals";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  ...insightStyles,
  blankInsightsContent: {
    ...insightStyles.insightsContent,
    color: "#555",
    padding: "2em 2.5em",
    fontSize: "0.875rem",
    lineHeight: 1.6,
    textAlign: "center",
  },
  link: {
    ...gStyles.fontBold,
    cursor: "pointer",
    border: "none",
    padding: 0,
  },
};

const NoResultsMessage = (props) => {
  const { label, entity, subject, proData, content, entityType } = props;
  const { styles } = useStyles(baseStyles, props);

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const triggerConciergeQuestionModal = useCallback(() => {
    showModal("proConcierge", { entity, entityType, subject, proData });
  }, [showModal, entity, entityType, subject, proData]);

  return (
    <div className={css(styles.blankInsightsContent)}>
      {content || (
        <Fragment>
          <button
            className={css(styles.link)}
            onClick={triggerConciergeQuestionModal}
            data-id="request-button"
          >
            Submit a request
          </button>
          {` for ${label} for this ${cleanEntityType(
            entityType
          )?.toLowerCase()}.`}
        </Fragment>
      )}
    </div>
  );
};

NoResultsMessage.propTypes = {
  entity: PropTypes.instanceOf(Map).isRequired,
  label: PropTypes.string,
  subject: PropTypes.string,
  proData: PropTypes.instanceOf(Map),
  content: PropTypes.node,
  entityType: PropTypes.string,
};
NoResultsMessage.defaultProps = {
  label: "details",
  subject: null, // Note: must match form value
  proData: null,
  content: null,
  entityType: "podcast",
};

export default NoResultsMessage;
