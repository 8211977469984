import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";

import colours from "../../../../styles/colours";
import accessibleClickProps from "../../../../utils/misc/accessibleClickProps";
import { insightStyles } from "../InsightsStyles";

import getPodcastStatusDetails from "utils/entity/getPodcastStatusDetails";

import useHasProForPodcast from "hooks/useHasProForPodcast";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  ...insightStyles,
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "-0.75rem 0",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    color: colours.insightsItems,
    marginRight: "1.25rem",
    fontSize: "2rem",
    alignSelf: "flex-start",
  },
  details: {},
  title: {
    ...gStyles.avalonBold,
    margin: 0,
    padding: 0,
  },
  status: {
    margin: 0,
    padding: 0,
    lineHeight: 1.5,
  },
  toggle: {
    ...gStyles.noButton,
    cursor: "pointer",
    marginLeft: "auto",
    justifySelf: "flex-end",
    paddingLeft: "1rem",
    alignSelf: "flex-start",
    paddingTop: "0.6em",
  },
};

const expandedStyles = {
  title: {
    marginTop: "0.6125em",
    marginBottom: "0.5em",
  },
};

const InsightsStatus = (props) => {
  const { podcast } = props;

  const [expanded, setExpanded] = useState(false);

  const iconStyles = details
    ? {
        icon: {
          color: details.iconColor,
        },
      }
    : null;

  const { styles } = useStyles(
    [baseStyles, expanded && expandedStyles, iconStyles],
    props
  );

  const userHasPro = useHasProForPodcast(podcast && podcast.get("id"));

  const details = useMemo(
    () => podcast && getPodcastStatusDetails(podcast, userHasPro),
    [podcast, userHasPro]
  );

  if (!podcast) {
    return null;
  }

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(!expanded);
  };

  if (!details) {
    return null;
  }
  const { icon, title, status, proImportant } = details;

  if (!proImportant) {
    return null;
  }

  return (
    <section
      className={css(styles.insightsSection)}
      {...accessibleClickProps(handleClick)}
      aria-label="Click to expand status details"
    >
      <div className={css(styles.insightsContent, styles.container)}>
        {icon && (
          <span className={css(styles.icon)}>
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
        <div className={css(styles.details)}>
          <p className={css(styles.title)}>
            <strong>{title}</strong>
          </p>
          {expanded && <p className={css(styles.status)}>{status}</p>}
        </div>
        <button className={css(styles.toggle)} onClick={handleClick}>
          <FontAwesomeIcon icon={expanded ? faCaretUp : faCaretDown} />
        </button>
      </div>
    </section>
  );
};

InsightsStatus.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  expanded: PropTypes.bool,
};

InsightsStatus.defaultProps = {
  podcast: null,
  expanded: false,
};

export default InsightsStatus;
