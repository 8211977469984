import { useEffect } from "react";
import { getPodcastRanksDataPromise } from "routines/podcast";

import {
  selectPodcastChartsData,
  selectPodcastChartsLoading,
  selectPodcastChartsMeta,
} from "selectors/charts";

import useReduxState from "hooks/useReduxState";
import useRoutinePromises from "hooks/useRoutinePromises";

export default function useInsightsRanksData(podcastId) {
  const { loadPodcastRanksData } = useRoutinePromises({
    loadPodcastRanksData: getPodcastRanksDataPromise,
  });

  // USE MEMOISE SELECTORS

  const ranksData = useReduxState((state) => {
    return selectPodcastChartsData(state, podcastId);
  }, []);

  const loading = useReduxState((state) => {
    return selectPodcastChartsLoading(state, podcastId);
  }, []);

  useEffect(() => {
    if (!ranksData && !loading) {
      loadPodcastRanksData({ podcastId });
    }
  }, [loadPodcastRanksData, loading, podcastId, ranksData]);

  return { ranksData, loading };
}

export function useInsightsChartsFilters(podcastId) {
  const filters = useReduxState((state) => {
    return selectPodcastChartsMeta(state, podcastId);
  }, []);

  return filters;
}
