import { css } from "aphrodite";
import { List, Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import getPodcastName from "utils/entity/getPodcastName";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import sendGAEvent from "utils/sendGAEvent";
import commaList from "utils/text/commaList";

import { useEntities } from "hooks/useEntity";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  link: {
    ...gStyles.fontBold,
    ":hover": {
      textDecoration: "underline",
    },
    ":focus": {
      textDecoration: "underline",
    },
  },
};

const NetworkSummary = (props) => {
  const { network, maxLength: passedMaxLength } = props;
  const { styles } = useStyles(baseStyles, props);
  const networkPodcastEntities = useEntities(
    "podcast",
    network.get("preview_entities") || List()
  );

  const { isWindowSizeOrMore } = useWindowSize();

  const handlePodcastClick = useCallback(
    (podcast) => () =>
      sendGAEvent({
        action: "networkSummaryPodcastLinkClick",
        network: network.get("title"),
        podcast_id: podcast.get("id"),
        podcast_name: getPodcastName(podcast),
      }),
    [network]
  );

  // TODO: Move to utility
  const getIdealCountFromStrLen = (strings, strlen) => {
    if (!strings || !strlen) {
      return null;
    }

    let accumLen = 0;
    let count = 0;

    for (let i = 0, len = strings.size; i < len; i++) {
      accumLen += strings.get(i).length;

      if (accumLen >= strlen) {
        break;
      }
      count++;
    }

    return Math.max(1, count);
  };

  if (!networkPodcastEntities) {
    return null;
  }

  const itemCount = network.get("podcast_count");
  let maxLength;

  if (passedMaxLength) {
    maxLength = passedMaxLength;
  } else if (isWindowSizeOrMore("xxlarge")) {
    maxLength = 300;
  } else if (isWindowSizeOrMore("xlarge")) {
    maxLength = 170;
  } else if (isWindowSizeOrMore("large")) {
    maxLength = 80;
  } else if (isWindowSizeOrMore("medium")) {
    maxLength = 140;
  } else if (isWindowSizeOrMore("small")) {
    maxLength = 80;
  } else {
    maxLength = 50;
  }

  const podcastTitles = networkPodcastEntities.map(
    (entityData) =>
      entityData && entityData.entity && entityData.entity.get("title")
  );
  const podcastCount = getIdealCountFromStrLen(podcastTitles, maxLength);

  const titleList =
    networkPodcastEntities &&
    commaList(
      networkPodcastEntities.slice(0, podcastCount).map(
        (entityData) =>
          entityData &&
          entityData.entity && (
            <a
              key={`podcast-title-${entityData.entity.get("id")}`}
              href={getPodcastUrl(entityData.entity)}
              className={css(styles.link)}
              onClick={handlePodcastClick(entityData.entity)}
            >
              {entityData.entity.get("title")}
            </a>
          ) // TODO: <EntityLink entity={entityData.entity} entity_type="podcast" />
      ),
      null,
      { skipConjunction: itemCount > podcastCount }
    );

  const additional =
    itemCount > podcastCount
      ? ` and ${itemCount - podcastCount} other podcasts.`
      : ".";

  return (
    <div>
      A podcast network featuring {titleList}
      {additional}
    </div>
  );
};

NetworkSummary.propTypes = {
  network: PropTypes.instanceOf(Map),
  maxLength: PropTypes.number,
};

NetworkSummary.defaultProps = {
  network: null,
  maxLength: null,
};

export default memo(NetworkSummary);
