import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  infoIcon: {
    fontSize: "1rem",
    color: "var(--color-neutral-d9)",

    ":hover": {
      cursor: "pointer",
    },
    position: "relative",
  },
  infoIconActive: {
    color: "var(--color-neutral-d2)",
  },
  exclaimedIcon: {
    marginRight: "0.5rem",
    color: colours.notice,
    fontSize: "0.8rem",

    [createBreakpoint({ min: 920 })]: {
      marginRight: "0.25rem",
    },
  },
  infoIconContainer: {
    display: "flex",
  },
};

export default function ReachContentHelpIcon(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { onClick, show, children, showWarning } = props;

  return (
    <span className={css(styles.infoIcon, show && styles.infoIconActive)}>
      {children}
      <div className={css(styles.infoIconContainer)}>
        {showWarning && (
          <BasicTooltip
            renderTooltip={() =>
              "Due to irregular listener activity, we are unable to accurately estimate audience reach."
            }
            position="top-start"
            zIndex={10000}
          >
            {(tooltipProps) => (
              <span {...tooltipProps} className={css(styles.exclaimedIcon)}>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  dataid="exclamation"
                />
              </span>
            )}
          </BasicTooltip>
        )}
        <FontAwesomeIcon
          icon={faInfoCircle}
          dataid="infoCircle"
          onClick={onClick}
        />
      </div>
    </span>
  );
}

ReachContentHelpIcon.propTypes = {
  onClick: PropTypes.func,
  show: PropTypes.bool,
};
