import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import PodchaserBrandLogo from "../../Branding/PodchaserBrandLogo";

import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  logo: {
    ...gStyles.avalonBold,
    ...gStyles.chromaticAbberationBlock,
    color: "#222",
    lineHeight: 1,
    padding: "0.25rem 1.25rem",
    letterSpacing: "0.1em",
    borderRadius: 100,
  },
  podchaserLogoContainer: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

const showPodchaserLogoStyles = {
  logoContainer: {
    marginLeft: ".75rem",
  },
};

const logoStyles = {
  link: {
    display: "inline",
  },
};

const PodchaserProLogo = (props) => {
  const { showPodchaserLogo, height, linked } = props;
  const { styles } = useStyles(
    [baseStyles, showPodchaserLogo && showPodchaserLogoStyles],
    props
  );

  const location = useLocation();

  const hasPro = useUserHasPro();
  const isOnProPage = hasPro
    ? location.pathname.includes("/profile/pro")
    : location.pathname === "/pro";

  const handleLinkClick = useCallback(() => {
    if (isOnProPage) {
      window.location.reload();
    }
    return true;
  }, [isOnProPage]);

  const { LinkComponent, linkProps } = useMemo(() => {
    if (linked) {
      return {
        LinkComponent: "a",
        linkProps: {
          href: hasPro ? "/profile/pro" : "/pro",
          target: "_blank",
          onClick: handleLinkClick,
          title: "Pro website",
        },
      };
    }

    return {
      LinkComponent: "span",
      linkProps: {},
    };
  }, [handleLinkClick, linked, hasPro]);

  return (
    <span className={css(styles.podchaserLogoContainer)}>
      <LinkComponent {...linkProps}>
        {showPodchaserLogo && (
          <Fragment>
            <PodchaserBrandLogo
              large
              light
              height={height}
              width="auto"
              styles={logoStyles}
            />{" "}
          </Fragment>
        )}
        <span className={css(styles.logoContainer)}>
          <span className={css(styles.logo)}>PRO</span>
        </span>
      </LinkComponent>
    </span>
  );
};

PodchaserProLogo.propTypes = {
  showPodchaserLogo: PropTypes.bool,
  onClick: PropTypes.func,
  height: PropTypes.number,
  linked: PropTypes.bool,
};

PodchaserProLogo.defaultProps = {
  showPodchaserLogo: false,
  onClick: null,
  height: 16,
  linked: true,
};

export default memo(PodchaserProLogo);
