import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import LoadMoreButton from "components/Buttons/LoadMoreButton";

import paginationActions from "actions/pagination";
import {
  selectListHasMore,
  selectListLoading,
  selectListTotal,
} from "selectors/pagination";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";

const ListLoadMore = (props) => {
  const { listKey, onLoad } = props;

  const { loading, hasMore, total } = useReduxState(
    (state) => ({
      loading: selectListLoading(state, { key: listKey }),
      hasMore: selectListHasMore(state, { key: listKey }),
      total: selectListTotal(state, { key: listKey }),
    }),
    [listKey]
  );

  const { nextPage } = useActionCreators(paginationActions);

  const handleClick = useCallback(() => {
    if (!loading && hasMore) {
      nextPage(listKey, {
        onLoad,
      });
    }
  }, [loading, hasMore, nextPage, listKey, onLoad]);

  return (
    <LoadMoreButton
      {...props}
      loading={loading}
      hasMore={hasMore}
      total={total}
      onClick={handleClick}
    />
  );
};

ListLoadMore.propTypes = {
  listKey: PropTypes.string,
};
ListLoadMore.defaultProps = {
  listKey: null,
};

export default memo(ListLoadMore);
