import { faVoteYea } from "@fortawesome/free-solid-svg-icons/faVoteYea";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare";
import { faBoxBallot } from "@fortawesome/pro-solid-svg-icons/faBoxBallot";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Map } from "immutable";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";

import InsightCard from "../../components/InsightCard";
import ReachContent, {
  firstContainerStyles,
} from "../insightReach/ReachContent";
import ReachContentHelpIcon from "../insightReach/ReachContentHelpIcon";
import ReachContentIcon from "../insightReach/ReachContentIcon";
import ReachPopUp from "../insightReach/ReachPopUp";
import { getPoliticalSkew } from "./util";

import sendGAEvent from "utils/sendGAEvent";

import useOutsideClickHandler from "hooks/useOutsideClickHandler";
import { useStyles } from "hooks/useStyles";
import useUserHasPoliticalSkewPermission from "hooks/useUserHasPoliticalSkewPermission";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...firstContainerStyles,
  noAvailable: {
    fontSize: "1rem",
    color: colours.newDarkGrey,
    textAlign: "center",
    margin: 0,
    cursor: "pointer",
  },
  noAvailableContainer: {
    position: "relative",
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    color: "var(--color-neutral-d5)",
    textAlign: "center",
    margin: 0,
  },
  popUpTitle: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    color: colours.black,
    textAlign: "center",
    margin: 0,
  },
  para: {
    ...gStyles.fontRegular,
    fontSize: "0.813rem",
    color: colours.black,
    textAlign: "center",
    margin: 0,
  },
  paraBold: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.black,
    textAlign: "center",
    margin: 0,
  },
  link: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.primary,
    textAlign: "center",
    margin: "0 0 0 0.188rem",

    ":hover": {
      cursor: "pointer",
    },
  },
  button: {
    width: "max-content",
    border: `1px solid ${colours.borderGrey}`,
    padding: "0.5rem 1rem",
    borderRadius: 25,
    ...gStyles.avalonBold,
    fontSize: "1rem",
    color: colours.black,
    textAlign: "center",
    margin: "1rem auto 0 auto",
  },
  icon: {
    fontSize: "1.25rem",
  },
  iconContainer: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    justifyContent: "center",
  },
  labelContainer: {
    borderRadius: 25,
    padding: "2px 1rem",
    marginBottom: "0.75rem",
  },
  label: {
    ...gStyles.avalonBold,
    fontSize: "1.25rem",
    textAlign: "center",
    margin: 0,
  },
};

const ReachPopUpStyles = {
  outer: {
    marginLeft: 0,

    [ScreenSizes.mdAndAbove]: {
      marginLeft: -260,
      bottom: 30,
    },
  },
  triangle: {
    right: -10,
    left: "auto",
  },
};

const REACH_ARTICLE =
  "https://support.podchaser.com/en/articles/10030080-political-skew-insights";

export default function PoliticalSkew(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { entity, insightCardStyles } = props;

  const [showPopUp, handleShowPopUp] = useState(false);

  const podcastProData = entity?.getIn(["user_data", "pro_data"]);
  const politicalSkew = podcastProData?.get("political_lean");
  const politicalLeanDegree = podcastProData?.get("political_lean_degree");

  const { isWindowSizeOrLess } = useWindowSize();
  const hasPoliticalskewPermisison = useUserHasPoliticalSkewPermission();
  const onMobile = isWindowSizeOrLess("small");

  const { color, icon1, icon2, icon3, backgroundColor, title } =
    getPoliticalSkew({ politicalLeanDegree, politicalSkew });

  const onClick = useCallback(() => {
    if (showPopUp) {
      handleShowPopUp(false);
    } else {
      sendGAEvent({
        action: "infoIconClick",
        context: "Content Insights",
        element: "Political Skew",
        page: "Podcast Insights",
        entityId: entity?.get("id"),
      });
      handleShowPopUp(true);
    }
  }, [showPopUp, entity]);

  const wrapperRef = useOutsideClickHandler(() => handleShowPopUp(false));

  const popUpDescription =
    "Our estimate of the political leaning of a podcast’s content, as determined by predictive models.";

  if (!hasPoliticalskewPermisison) {
    return null;
  }

  return (
    <InsightCard styles={[insightCardStyles]}>
      <ReachContent>
        <div className={css(styles.firstContainer)}>
          <ReachContentIcon icon={faBoxBallot} dataid="insightContent" />
          <h2 className={css(styles.title)}>Political Skew</h2>
          <ReachContentHelpIcon onClick={onClick} show={showPopUp}>
            {showPopUp && (
              <ReachPopUp styles={ReachPopUpStyles}>
                <h2 className={css(styles.popUpTitle)}>Political Skew</h2>
                <p className={css(styles.para)}>{popUpDescription} </p>
                {onMobile ? (
                  <a
                    className={css(styles.paraBold)}
                    href={REACH_ARTICLE}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more &nbsp;&nbsp;
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare}
                      dataid="expand"
                    />
                  </a>
                ) : (
                  <p className={css(styles.para)}>
                    Learn more about
                    <a
                      ref={wrapperRef}
                      className={css(styles.link)}
                      href={REACH_ARTICLE}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Political Skew
                    </a>
                  </p>
                )}
                {onMobile && (
                  <button onClick={onClick} className={css(styles.button)}>
                    Got It
                  </button>
                )}
              </ReachPopUp>
            )}
          </ReachContentHelpIcon>
        </div>

        {politicalSkew === null ||
        politicalSkew === undefined ||
        backgroundColor === "" ? (
          <div className={css(styles.noAvailableContainer)}>
            <h3 className={css(styles.noAvailable)}>Not Available</h3>
          </div>
        ) : (
          <div>
            <div
              className={css(styles.labelContainer)}
              style={{ backgroundColor: backgroundColor }}
            >
              <h4 className={css(styles.label)} style={{ color: color }}>
                {title}
              </h4>
            </div>
            <div className={css(styles.iconContainer)}>
              <span className={css(styles.icon)} style={{ color: icon1 }}>
                <FontAwesomeIcon icon={faVoteYea} dataid="checkToSlot1" />
              </span>
              <span className={css(styles.icon)} style={{ color: icon2 }}>
                <FontAwesomeIcon icon={faVoteYea} dataid="checkToSlot2" />
              </span>
              <span className={css(styles.icon)} style={{ color: icon3 }}>
                <FontAwesomeIcon icon={faVoteYea} dataid="checkToSlot3" />
              </span>
            </div>
          </div>
        )}
      </ReachContent>
    </InsightCard>
  );
}

PoliticalSkew.propTypes = {
  entity: PropTypes.instanceOf(Map),
  insightCardStyles: PropTypes.object,
};

PoliticalSkew.defaultProps = {
  entity: Map(),
  insightCardStyles: null,
};
