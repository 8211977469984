import { faExternalLink } from "@fortawesome/pro-solid-svg-icons/faExternalLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import Dotdotdot from "components/Common/Dotdotdot";

import CardTitle from "../CardTitle";
import EntityCardImage from "../EntityCardImage";
import NetworkSummary from "./NetworkSummary";

import { useStyles } from "hooks/useStyles";
import userHasInternalPermission from "hooks/useUserHasInternalPermission";

import cardStyles from "styles/CardStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  networkDesktopCard: {
    ...cardStyles.desktopCard,
  },
  networkDesktopCardNoPanel: {
    ...cardStyles.cardNoPanel,
  },
  topRow: {
    ...cardStyles.topRow,
    paddingBottom: 0,

    [ScreenSizes.mdAndAbove]: {
      borderBottom: "none",
    },
  },
  infoContainer: {
    ...cardStyles.desktopCardEntityInfo,
  },
  descriptionRow: {
    ...cardStyles.desktopCardDescription,
    display: "flex",
    flexDirection: "row",
  },
  externalLinkIcon: {
    fontSize: "0.5em",
    marginLeft: "1em",
    color: "#777",
    verticalAlign: "middle",
  },
};

const insidePanelStyles = {
  networkDesktopCard: {
    ...cardStyles.noRaisedCard,
    ...cardStyles.flatCard,
  },
};

const NetworkDesktopCard = (props) => {
  const { network, noPanel, hideSecondaryButtons, renderOnMount, insidePanel } =
    props;
  const userIsInternal = userHasInternalPermission();
  const { styles } = useStyles(
    [baseStyles, insidePanel && insidePanelStyles],
    props
  );
  // const hasFollowers = network && network.get('follower_user_ids') && network.get('follower_user_ids').size > 0;

  const entityLineProps = useMemo(
    () => ({
      additionalContent: network.get("url") && !userIsInternal && (
        <span className={css(styles.externalLinkIcon)}>
          <FontAwesomeIcon icon={faExternalLink} />
        </span>
      ),
    }),
    [network, styles.externalLinkIcon, userIsInternal]
  );

  const renderDescription = () => (
    <Dotdotdot clamp={5} tagName="div" className={css(styles.descriptionRow)}>
      {network.get("description") || <NetworkSummary {...props} />}
    </Dotdotdot>
  );

  const renderTopRow = () => (
    <div className={css(styles.topRow)}>
      <EntityCardImage entity={network} entityType="network" />
      <div className={css(styles.infoContainer)}>
        <CardTitle
          entity={network}
          entityType="network"
          hideSecondaryButtons={hideSecondaryButtons}
          entityLineProps={entityLineProps}
          renderOnMount={renderOnMount}
        />
        {renderDescription()}
      </div>
    </div>
  );

  if (!network) {
    return null;
  }

  return (
    <div
      data-id="network-desktop-card"
      className={css(
        styles.networkDesktopCard,
        noPanel && styles.networkDesktopCardNoPanel,
        styles.entityCard
      )}
    >
      {renderTopRow()}
    </div>
  );
};

NetworkDesktopCard.propTypes = {
  network: PropTypes.instanceOf(Map),
  noPanel: PropTypes.bool,
  hideSecondaryButtons: PropTypes.bool,
  renderOnMount: PropTypes.bool,
  insidePanel: PropTypes.bool,
};

NetworkDesktopCard.defaultProps = {
  network: null,
  noPanel: false,
  hideSecondaryButtons: true, // false,
  renderOnMount: false,
  insidePanel: false,
};

export default NetworkDesktopCard;
