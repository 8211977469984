import { useCallback } from "react";

import paginationActions from "actions/pagination";
import { selectSearchTerm } from "selectors/pagination";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";

function useSearchTerm(props) {
  const { listKey, eventContext } = props;

  const { setSearchTerm } = useActionCreators({
    setSearchTerm: paginationActions.setSearchTerm,
  });

  const term = useReduxState(
    (state) => selectSearchTerm(state, { key: listKey }),
    [listKey]
  );

  const onSearchTermRequest = useCallback(() => {
    sendGAEvent({
      action: "searchList",
      term,
      ...eventContext,
    });
  }, [term, eventContext]);

  const handleSearch = useCallback(
    (value) => {
      const trimmedValue = value.trim();
      if (listKey) {
        setSearchTerm(listKey, trimmedValue, onSearchTermRequest);
      }
    },
    [listKey, onSearchTermRequest, setSearchTerm]
  );

  const handleChange = useCallback(
    (value) => {
      handleSearch(value);
    },
    [handleSearch]
  );

  return { term, handleChange };
}

export default useSearchTerm;
