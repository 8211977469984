import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";

import ExportButton from "../components/ExportButton";
import InsightsAudience from "./InsightAudienceAsync";
import InsightReach from "./insightReach/InsightReach";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  mobileContainer: {
    display: "block",
    padding: "0 3rem",
    marginBottom: "1.5rem",

    [ScreenSizes.mdAndAbove]: {
      display: "none",
    },
  },
  containerWithoutExport: {
    marginBottom: "2rem",
  },
};

const InsightsAudienceContainer = (props) => {
  const { podcast, proData, audienceRef } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const [showPopUp, setShowPopUp] = useState({ active: null });

  const handlePopUp = useCallback((active) => {
    setShowPopUp((prev) => ({
      active: prev.active === active ? null : active,
    }));
  }, []);

  return (
    <>
      <InsightReach
        podcast={podcast}
        proData={proData}
        passedRef={audienceRef}
        handlePopUp={handlePopUp}
        showPopUp={showPopUp}
      />
      <div className={css(styles.mobileContainer)}>
        <ExportButton podcastId={podcast?.get("id")} variation="pink" />
      </div>
      <InsightsAudience
        podcast={podcast}
        proData={proData}
        handlePopUp={handlePopUp}
        showPopUp={showPopUp}
      />
    </>
  );
};

InsightsAudienceContainer.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  proData: PropTypes.instanceOf(Map),
  audienceRef: PropTypes.object,
};
InsightsAudienceContainer.defaultProps = {
  podcast: null,
  proData: null,
  audienceRef: null,
};

export default InsightsAudienceContainer;
