import { useEffect } from "react";

function useNavigationScroll({
  activeLocation,
  lastScrollableElementTop,
  refs,
  scrollPosition,
  setActiveLocation,
  isNotScrolling,
}) {
  useEffect(() => {
    refs.forEach(({ ref, id }) => {
      const isNotActiveLocation = activeLocation !== id;

      const isScrollInsideElementHeight =
        scrollPosition > ref?.current?.offsetTop &&
        scrollPosition - ref?.current?.offsetTop < 110;

      if (
        isNotActiveLocation &&
        isScrollInsideElementHeight &&
        scrollPosition < lastScrollableElementTop &&
        isNotScrolling
      ) {
        setActiveLocation(id);
      }
    });
  }, [
    scrollPosition,
    setActiveLocation,
    refs,
    lastScrollableElementTop,
    isNotScrolling,
    activeLocation,
  ]);
}

export default useNavigationScroll;
