import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";

import NetworkCardContainer from "components/Entities/Cards/NetworkCards/NetworkCardContainer";
import Title from "components/Entities/Page/TitleAsync";

import { insightStyles, secondaryTitleStyles } from "../InsightsStyles";

import { getNetworkUrlByEntity } from "utils/entity/getNetworkUrl";

import { useStyles } from "hooks/useStyles";
import userHasInternalPermission from "hooks/useUserHasInternalPermission";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/FontStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...insightStyles,
  insightsContent: {
    padding: "0 1rem",

    [ScreenSizes.lgAndAbove]: {
      padding: 0,
    },
  },
  network: {
    display: "flex",
    flexDirection: "row",
  },
  networkImage: {
    marginRight: "0.5rem",
  },
  networkDetails: {},
  networkTitle: {
    ...gStyles.avalonBold,
  },
  networkBio: {},
};

const InsightsNetwork = (props) => {
  const { podcast, proData, passedRef } = props;
  const { styles } = useStyles(baseStyles, props);

  const userIsInternal = userHasInternalPermission();

  const { isWindowSizeOrLess } = useWindowSize();

  const network = proData.get("network");

  if (!podcast || network?.size === 0) {
    return null;
  }

  let formattedNetwork = network;

  if (userIsInternal) {
    formattedNetwork = new Map(network)?.set(
      "url",
      getNetworkUrlByEntity(network)
    );
  }

  return (
    <section className={css(styles.insightsSection)} ref={passedRef}>
      <Title
        title="Podcast Network"
        TitleComponent="h2"
        styles={secondaryTitleStyles}
        noTopPadding
      />
      <div className={css(styles.insightsContent)}>
        <NetworkCardContainer
          entity={formattedNetwork}
          mobile={isWindowSizeOrLess("large")}
          renderOnMount
        />
      </div>
    </section>
  );
};

InsightsNetwork.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  proData: PropTypes.instanceOf(Map),
};
InsightsNetwork.defaultProps = {
  podcast: null,
  proData: null,
};

export default InsightsNetwork;
