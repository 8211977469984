import { Map } from "immutable";
import PropTypes from "prop-types";
import React, { useMemo, useCallback, useState } from "react";

import Title from "components/Entities/Page/Title";
import NoResultsMessage from "pages/Common/NoResultsMessage";

import { secondaryTitleStyles } from "../../InsightsStyles";
import InsightsReachExplainer from "./InsightsReachExplainer";
import EpisodesListeners from "./sections/EpisodesListeners";
import FlagReach from "./sections/FlagReach";
import InsightWarning from "./sections/InsightWarning";
import MonthlyListeners from "./sections/MonthlyListeners";
import Powerscore from "./sections/Powerscore";

import modalActions from "actions/modals";
import { formatRange } from "utils/text/formatRange";
import getVerifiedData from "utils/verifiedBanner/getVerifiedData";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  container: {
    display: "grid",
    gridTemplateRows: "max-content 1fr max-content",
    gridGap: "1rem",
    position: "relative",
  },
  title: {
    ...gStyles.avalonRegular,
    padding: 0,
    margin: 0,
    fontSize: "1rem",
    fontWeight: "inherit",

    [ScreenSizes.lgAndAbove]: {
      padding: "0 1rem",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.25rem",
      padding: 0,
    },
  },
  titleContainer: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: "1rem",
    alignItems: "center",
    marginBottom: "-1rem",
  },
  disclaimerTitle: {
    marginBottom: 0,
  },
  cardContainer: {
    display: "grid",
    gridGap: 0,
    position: "relative",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    borderRadius: "0rem",

    [ScreenSizes.mdAndAbove]: {
      borderRadius: "0.75rem",
      boxShadow: colours.InsightCardBoxShadow,
    },
    [createBreakpoint({ min: 920 })]: {
      boxShadow: colours.InsightCardBoxShadow,
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
  firstDynamicGrid: {
    [createBreakpoint({ max: 920 })]: {
      gridArea: "1 / 1 / 2 / 3",
    },
  },
  secondDynamicGrid: {
    [createBreakpoint({ max: 920 })]: {
      gridArea: "2 / 1 / 3 / 2",
    },
  },
  thirdDynamicGrid: {
    [createBreakpoint({ max: 920 })]: {
      gridArea: "2 / 2 / 3 / 3",
    },
  },
  click: {
    border: "none",
    outline: "none",
    color: colours.primary,
    padding: 0,

    ":hover": {
      cursor: "pointer",
    },
  },
};

const daysAgo = (days) => {
  let today = new Date();
  today.setDate(today.getDate() - Math.abs(days));
  return today;
};

export default function InsightReach(props) {
  const { proData, podcast, passedRef, handlePopUp, showPopUp, hideTitle } =
    props;

  const { styles, css } = useStyles(baseStyles, props);

  const [showWarning, setShowWarning] = useState(true);
  const { isVerified } = getVerifiedData(podcast);

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const triggerConciergeQuestionModal = useCallback(() => {
    showModal("proConcierge", {
      entity: podcast,
      entityType: "podcast",
      subject: "report issue",
      proData,
    });
  }, [showModal, podcast, proData]);

  const reachEstimate = useMemo(
    () =>
      proData &&
      formatRange(
        proData.getIn(["reach_estimate", "from"]),
        proData.getIn(["reach_estimate", "to"])
      ),
    [proData]
  );

  const averageEstimatedEpisodeReach = useMemo(() => {
    const fromEpisodeRange = proData?.getIn([
      "estimated_episode_reach_range",
      "from",
    ]);
    const toEpisodeRange = proData?.getIn([
      "estimated_episode_reach_range",
      "to",
    ]);

    if (toEpisodeRange <= 1000) {
      return "Under 1k";
    }

    return proData && formatRange(fromEpisodeRange, toEpisodeRange);
  }, [proData]);

  const hasReachData = proData?.get("has_reach_data");
  const powerScore = proData?.get("power_score");
  const shouldShowEstimates = proData?.get("should_show_estimates");
  const exclusiveTo = podcast?.get("exclusive_to");
  const createdAt = podcast?.get("created_at");
  const firstEpisodeDate = podcast?.get("date_of_first_episode");

  const canShowWarningPopUp =
    hasReachData && showWarning && !shouldShowEstimates;
  const canShowWarningIcon = hasReachData && !shouldShowEstimates;

  const reachMessage = useMemo(() => {
    const thirtyDays = daysAgo(30);
    const threeDays = daysAgo(3);
    const fomattedCreatedAt = new Date(createdAt);
    const fomattedFirstEpisodeDate = new Date(firstEpisodeDate);

    if (exclusiveTo === "spotify") {
      return (
        <span>
          We are currently unable to provide accurate audience reach estimates
          for Spotify-exclusive podcasts. If this podcast isn’t exclusive to
          Spotify, please
          <button
            className={css(styles.click)}
            onClick={triggerConciergeQuestionModal}
          >
            &nbsp;let us know.
          </button>
        </span>
      );
    }

    if (fomattedCreatedAt > threeDays) {
      return (
        <span>
          This podcast was recently added to Podchaser. We’re in the process of
          synchronizing its data, which typically takes a few hours. If you
          notice an issue, please
          <button
            className={css(styles.click)}
            onClick={triggerConciergeQuestionModal}
          >
            &nbsp;let us know.
          </button>
        </span>
      );
    }

    if (fomattedFirstEpisodeDate > thirtyDays) {
      return (
        <span>
          This is a new podcast. We’re still gathering the necessary data to
          provide accurate estimates. Please check back later. If you notice an
          issue, please
          <button
            className={css(styles.click)}
            onClick={triggerConciergeQuestionModal}
          >
            &nbsp;let us know.
          </button>
        </span>
      );
    }

    return null;
  }, [
    createdAt,
    css,
    exclusiveTo,
    firstEpisodeDate,
    styles.click,
    triggerConciergeQuestionModal,
  ]);

  if (!proData || !podcast) {
    return null;
  }

  if (!hasReachData && reachMessage !== null) {
    return (
      <>
        {!hideTitle && (
          <div className={css(styles.titleContainer, styles.disclaimerTitle)}>
            <Title
              title="Reach"
              TitleComponent="h2"
              styles={secondaryTitleStyles}
              noTopPadding
            />
          </div>
        )}
        <NoResultsMessage
          entity={podcast}
          entityType={"podcast"}
          label="audience details"
          subject="request demographics"
          proData={proData}
          content={!hasReachData && reachMessage}
        />
      </>
    );
  }

  return (
    <div className={css(!hideTitle && styles.container)} ref={passedRef}>
      {!hideTitle && (
        <div className={css(styles.titleContainer)}>
          <Title
            title="Reach"
            TitleComponent="h2"
            styles={secondaryTitleStyles}
            noTopPadding
          />
          {!canShowWarningPopUp && <FlagReach podcast={podcast} />}
        </div>
      )}
      {canShowWarningPopUp ? (
        <InsightWarning
          showWarning={showWarning}
          setShowWarning={setShowWarning}
          entityId={podcast?.get("id")}
          entityType="podcast"
          title="Irregular listener activity detected"
          description="We are unable to accurately estimate this podcast's reach at this time."
        />
      ) : (
        <div className={css(styles.cardContainer)}>
          <div className={css(styles.firstDynamicGrid)}>
            <Powerscore
              showPopUp={showPopUp}
              podcastId={podcast?.get("id")}
              handlePopUp={handlePopUp}
              showWarning={canShowWarningIcon}
              powerScore={powerScore}
            />
          </div>
          <div className={css(!showWarning && styles.secondDynamicGrid)}>
            <MonthlyListeners
              reachEstimate={reachEstimate}
              showPopUp={showPopUp}
              podcastId={podcast?.get("id")}
              handlePopUp={handlePopUp}
              showWarning={canShowWarningIcon}
              isVerified={isVerified}
            />
          </div>
          <div className={css(!showWarning && styles.thirdDynamicGrid)}>
            <EpisodesListeners
              showPopUp={showPopUp}
              handlePopUp={handlePopUp}
              entityId={podcast?.get("id")}
              averageEstimatedEpisodeReach={averageEstimatedEpisodeReach}
              showWarning={canShowWarningIcon}
              isVerified={isVerified}
            />
          </div>
        </div>
      )}
      <InsightsReachExplainer podcastId={podcast?.get("id")} />
    </div>
  );
}

InsightReach.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  proData: PropTypes.instanceOf(Map),
};

InsightReach.defaultProps = {
  podcast: null,
  proData: null,
};
