import { css } from "aphrodite";
import PropTypes from "prop-types";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  spiralBackground: {
    backgroundImage: "url(/images/background-spiral.jpg)",
    backgroundSize: "200% auto",
    backgroundAttachment: "fixed",
    opacity: 0.1,
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    display: "block",
    transition: generateTransition({ target: "transform" }),
  },
};

const SpiralBackground = (props) => {
  const { position, fixed, size, additionalStyles } = props;
  const customStyles = {
    spiralBackground: {
      backgroundPosition: position || "bottom right",
      backgroundAttachment: fixed ? "fixed" : "initial",
      backgroundSize: size || "200% auto",
      ...(additionalStyles || {}),
    },
  };
  const { styles } = useStyles([baseStyles, customStyles], props);

  return <span className={css(styles.spiralBackground)} />;
};

SpiralBackground.propTypes = {
  position: PropTypes.string,
  fixed: PropTypes.bool,
  additionalStyles: PropTypes.object,
  size: PropTypes.string,
};

SpiralBackground.defaultProps = {
  position: "bottom right",
  fixed: true,
  additionalStyles: null,
  size: null,
};

export default SpiralBackground;
