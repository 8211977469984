import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const downloadInsightsPdf = createRoutine("DOWNLOAD_INSIGHTS_PDF");
export const downloadInsightsPdfPromise = promisifyRoutine(downloadInsightsPdf);

export const loadMyInsights = createRoutine("LOAD_MY_INSIGHTS");
export const loadMyInsightsPromise = promisifyRoutine(loadMyInsights);

export const loadSpecificInsightsPDF = createRoutine(
  "LOAD_SPECIFIC_INSIGHTS_PDF"
);
export const loadSpecificInsightsPDFPromise = promisifyRoutine(
  loadSpecificInsightsPDF
);

export const loadSpecificArchiveInsightsPDF = createRoutine(
  "LOAD_SPECIFIC_ARCHIVE_INSIGHTS_PDF"
);
export const loadSpecificArchiveInsightsPDFPromise = promisifyRoutine(
  loadSpecificArchiveInsightsPDF
);
