import abbreviate from "number-abbreviate";

const needsDecimal = (number) =>
  (number > 1000 && number < 10000) ||
  (number > 1000000 && number < 10000000) ||
  (number > 1000000000 && number < 10000000000);

const cleanAbbreviate = (number) =>
  abbreviate(number, needsDecimal(number) ? 1 : 0);

export const REACH_NUMBER_LOW = 200;

export const formatRange = (from = 0, to = 0, options = {}) => {
  const settings = {
    showUnderUpTo: REACH_NUMBER_LOW, // If to is < showUnderUpTo, show "Under [showUnderUpTo]"
    showOverAfter: false, // If from is > showOverAfter, show "Over [showOverAfter]"
    interchangeable: true,
    returnOnZero: null,
    ...options,
  };

  let finalTo = Math.round(to);
  let finalFrom = Math.round(from) || 0;

  if (finalFrom > finalTo && finalTo && settings.interchangeable) {
    finalFrom = Math.round(to);
    finalTo = Math.round(from);
  }
  if (
    settings.showUnderUpTo &&
    finalTo > finalFrom &&
    finalTo < settings.showUnderUpTo
  ) {
    return `Under ${settings.showUnderUpTo}`;
  } else if (settings.showOverAfter && finalFrom > settings.showOverAfter) {
    return `Over ${settings.showOverAfter}`;
  } else if (finalTo > 0 && finalFrom > 0) {
    return `${cleanAbbreviate(finalFrom)}-${cleanAbbreviate(finalTo)}`;
  } else if (finalTo > 0) {
    return `Under ${cleanAbbreviate(finalTo)}`;
  } else if (finalFrom > 0) {
    return `Over ${cleanAbbreviate(finalFrom)}`;
  }

  return settings.returnOnZero;
};
