import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";

import InsightCard from "../../../components/InsightCard";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  container: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: "0.5rem",
    alignItems: "center",
    minHeight: 93.5,
    padding: 0,

    [ScreenSizes.smAndAbove]: {
      gridGap: "1rem",
    },

    [createBreakpoint({ min: 895 })]: {
      padding: "0 1rem",
      gridTemplateRows: "1fr",
      gridTemplateColumns: "1fr max-content",
    },
  },
  topContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: "0.5rem",
  },
  titleContainer: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "Center",
  },
  title: {
    ...gStyles.fontBold,
    color: colours.notice,
    fontSize: "0.75rem",
    margin: 0,

    [ScreenSizes.smAndAbove]: {
      fontSize: "0.813rem",
    },
  },
  details: {
    ...gStyles.fontRegular,
    color: colours.bodyText,
    fontSize: "0.75rem",
    margin: 0,
  },
  icon: {
    color: colours.notice,
    fontSize: "0.813rem",
    textAlign: "center",
  },
};

const SHOW_ANYWAYS_STYLES = {
  button: {
    position: "relative",
    ...gStyles.avalonBold,
    color: colours.bodyText,
    padding: "0.5rem 1rem",
    width: "max-content",
    marginLeft: "auto",
    marginRight: 0,
  },
};

const reachStyles = {
  cardContainer: {
    display: "grid",
    alignItems: "center",
    boxShadow:
      "0 2px 4px rgb(154 160 185 / 7%), 0 6px 11px rgb(166 173 201 / 20%)",
    borderRadius: 0,
    padding: "1.5rem 1rem",

    [ScreenSizes.mdAndAbove]: {
      borderRadius: 12,
    },

    [createBreakpoint({ min: 895 })]: {
      padding: "1rem",
    },
  },
};

export default function InsightWarning(props) {
  const { styles, css } = useStyles(baseStyles, props);

  const {
    description,
    title,
    showWarning,
    setShowWarning,
    entityId,
    entityType,
  } = props;

  const handleShow = useCallback(() => {
    sendGAEvent({
      action: "Show anyway button clicked",
      context: "Insight Reach Section",
      page: "Podcast Insight",
      entityId,
      entityType,
    });
    setShowWarning(!showWarning);
  }, [entityId, entityType, setShowWarning, showWarning]);

  return (
    <InsightCard styles={reachStyles} fade={!showWarning}>
      <div className={css(styles.container)}>
        <div className={css(styles.topContainer)}>
          <section className={css(styles.titleContainer)}>
            <span className={css(styles.icon)}>
              <FontAwesomeIcon icon={faExclamationTriangle} dataid="warning" />
            </span>
            <h4 className={css(styles.title)}>{title}</h4>
          </section>
          <h5 className={css(styles.details)}>{description}</h5>
        </div>
        <StandardButton
          variation={"white"}
          fontSize=".75rem"
          label="Show Anyway"
          flat
          customStyles={SHOW_ANYWAYS_STYLES}
          key="adBuyingButton"
          onClick={handleShow}
        />
      </div>
    </InsightCard>
  );
}

InsightWarning.propTypes = {
  children: PropTypes.node,
};

InsightWarning.defaultProps = {
  children: null,
};
