import React from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import { useStyles } from "hooks/useStyles";

import {
  getAuthorityForegroundColor,
  getAuthorityBackgroundColour,
} from "styles/authorityStyles";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  centerScore: {
    ...gStyles.avalonBold,
    fontSize: "0.75rem",
    margin: 0,

    letterSpacing: "0",
    borderRadius: "0.875rem",
    padding: "0.1rem 0.5rem",
  },
};

export default function PowerScore(props) {
  const { score, podcastId } = props;

  const { styles, css } = useStyles(baseStyles, props);

  return (
    <BasicTooltip
      renderTooltip={() => (podcastId ? "Power Score" : "Not Available")}
      zIndex={99999}
      forceOpen={true}
    >
      {(tooltipProps) => (
        <p
          style={{
            backgroundColor: getAuthorityBackgroundColour(score),
            color: getAuthorityForegroundColor(score),
          }}
          className={css(styles.bold, styles.centerScore)}
          {...tooltipProps}
        >
          {score || "--"}
        </p>
      )}
    </BasicTooltip>
  );
}
