import colours from "../../../styles/colours";
import fontStyles from "../../../styles/FontStyles";
import gStyles from "../../../styles/GenericStyles";
import ScreenSizes from "../../../styles/ScreenSizes";

export default {
  ul: {
    ...gStyles.ul,
  },
  li: {
    ...gStyles.li,
  },
  dummy: {
    filter: "blur(4px)",
    cursor: "default",
    wordSpacing: "-0.3em",
  },
  link: {
    color: colours.primary,
    ":hover": {
      textDecoration: "underline",
    },
    ":focus": {
      textDecoration: "underline",
    },
  },
  faqWrapper: {
    marginTop: "2rem",
  },
  faq: {
    width: "100%",
    marginBottom: "1rem",
    background: "#fff",
    borderRadius: 4,
    border: `0.063rem solid ${colours.newLightGreyBox}`,
  },
  proButton: {
    color: colours.primary,
    fontWeight: 600,
    border: "none",
    background: "transparent",
    padding: 0,
    cursor: "pointer",
  },
};

export const secondaryTitleStyles = {
  title: {
    fontSize: "1rem",
  },
  titleContainer: {
    ...gStyles.avalonBold,
    padding: "0 1rem",
    marginLeft: 0,
    marginBottom: "0.75rem",
    fontSize: "1rem",

    [ScreenSizes.lgAndAbove]: {
      padding: 0,
      marginBottom: "1rem",
    },
  },
  inlineContent: {
    width: "max-content",
    marginTop: 0,
  },
};
