import PropTypes from "prop-types";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  reachDataContainer: {
    display: "grid",
    placeItems: "center",
    gridTemplateRows: "max-content 1fr",
    gridGap: "1rem",
    minHeight: 91.5,
    height: "100%",
  },
};

export const firstContainerStyles = {
  firstContainer: {
    display: "grid",
    alignItems: "center",
    justifyItems: "start",
    gridTemplateColumns: "max-content 1fr max-content",
    gridGap: "0.5rem",
    fontSize: "1rem",
    width: "100%",
  },
};

export default function ReachContent(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { children } = props;

  return <div className={css(styles.reachDataContainer)}>{children}</div>;
}

ReachContent.propTypes = {
  icon: PropTypes.object,
  infoIcon: PropTypes.node,
  children: PropTypes.node,
};
