import { faLightbulb } from "@fortawesome/free-regular-svg-icons/faLightbulb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import sendGAEvent from "utils/sendGAEvent";
import { REACH_NUMBER_LOW } from "utils/text/formatRange";

import usePodcastData from "hooks/usePodcastData";
import useProDataForPodcast from "hooks/useProDataForPodcast";
import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    padding: "1rem",
    fontSize: "0.75rem",
    lineHeight: "1.125rem",

    [ScreenSizes.mdAndAbove]: {
      padding: 0,
    },
  },
  icon: {
    marginRight: "0.75rem",
    fontSize: "1rem",
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  textMargin: {
    marginTop: "0.5rem",
  },
};

const REACH_NUMBER_RELATIONS_LINK =
  "https://support.podchaser.com/en/articles/8672076-demographics-and-reach";

const THIRTY_DAYS_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 30;

export default function InsightsReachExplainer(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcastId } = props;

  const podcast = usePodcastData(podcastId);

  const { entity: proData } = useProDataForPodcast(podcastId);

  if (!podcast || !proData) {
    return null;
  }

  const reachEstimateFrom = proData.getIn(["reach_estimate", "from"]) || 0;
  const reachEstimateTo = proData.getIn(["reach_estimate", "to"]) || 0;
  const estimatedEpisodeReachRangeFrom =
    proData.get("estimated_episode_reach") || 0;

  const createdAt = new Date(podcast.get("created_at"));
  const dateOfFirstEpisode = new Date(podcast.get("date_of_first_episode"));

  const showEpisodeListenerGreaterThanMonthlyReach =
    estimatedEpisodeReachRangeFrom > reachEstimateFrom &&
    estimatedEpisodeReachRangeFrom > reachEstimateTo;

  const showPodcastNewMessage =
    estimatedEpisodeReachRangeFrom < REACH_NUMBER_LOW &&
    reachEstimateFrom < REACH_NUMBER_LOW &&
    reachEstimateTo < REACH_NUMBER_LOW;

  const isPodcastNew = new Date() - createdAt < THIRTY_DAYS_IN_MILLISECONDS;
  const isFirstEpisodeOlderThanThirtyDays =
    new Date() - dateOfFirstEpisode < THIRTY_DAYS_IN_MILLISECONDS;

  const showNewMessage = isPodcastNew && isFirstEpisodeOlderThanThirtyDays;

  if (
    !showEpisodeListenerGreaterThanMonthlyReach &&
    !showPodcastNewMessage &&
    !showNewMessage
  ) {
    return null;
  }

  return (
    <div className={css(styles.container)}>
      {(showNewMessage ||
        showEpisodeListenerGreaterThanMonthlyReach ||
        showPodcastNewMessage) && (
        <FontAwesomeIcon icon={faLightbulb} className={css(styles.icon)} />
      )}
      <div>
        {showEpisodeListenerGreaterThanMonthlyReach && (
          <p>
            <b>Why are episode listens higher than total listens?</b> This may
            occur when a podcast publishes infrequently or is currently on
            hiatus, as total listens will often spike with the release of a new
            episode. Contact the podcast for precise listenership. Check out our{" "}
            {/*  eslint-disable react/jsx-no-target-blank */}
            <a
              href={REACH_NUMBER_RELATIONS_LINK}
              target="_blank"
              className={css(styles.link)}
              onClick={() => {
                sendGAEvent({
                  action: "articleClick",
                  explainer: "EstimatedEpisodeListenersSurpassMonthlyReach",
                  context: "InsightsPage",
                  podcastId,
                });
              }}
            >
              reach numbers & relationships support article.
            </a>
          </p>
        )}
        {(showNewMessage || showPodcastNewMessage) && (
          <p data-testid="volatility">
            <b>Potential volatility:</b> Reach estimates for new podcasts may be
            more volatile as the podcast is still establishing its audience.
            Contact the podcast for precise listenership. Check out our{" "}
            {/*  eslint-disable react/jsx-no-target-blank */}
            <a
              href={REACH_NUMBER_RELATIONS_LINK}
              target="_blank"
              className={css(styles.link)}
              onClick={() => {
                sendGAEvent({
                  action: "articleClick",
                  explainer: "PodcastNew",
                  context: "InsightsPage",
                  podcastId,
                });
              }}
            >
              reach numbers & relationships support article.
            </a>
          </p>
        )}
      </div>
    </div>
  );
}

InsightsReachExplainer.propTypes = {
  podcastId: PropTypes.number,
};
