import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const BrandSafety = Loadable(() => import("./BrandSafety"));

function BrandSafetyAsync(props) {
  return <LoadableLoading {...props} width="100%" height="37.375rem" />;
}

const BrandSafetyContainer = (props) => {
  return <BrandSafety {...props} fallback={<BrandSafetyAsync />} />;
};

export default BrandSafetyContainer;
