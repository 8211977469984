import cardStyles from "../../../styles/CardStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

export const panelStyles = {
  ...cardStyles.raisedCardSubtle,
  borderRadius: 12,
};

export const removePanelStyles = {
  background: "none",
  borderRadius: 0,
  border: "none",
  [ScreenSizes.mdAndAbove]: {
    background: "none",
    borderRadius: 0,
    border: "none",
  },
};

export const insightStyles = {
  insights: {
    padding: "0 0.75rem",
    flex: "1 1 100%",
  },
  insightsSection: {
    marginBottom: "3rem",
    width: "100%",

    ":last-child": {
      marginBottom: 0,
    },
  },
  insightsTitleIcon: {
    marginRight: "0.125rem",
  },
  audienceAndSafetyContainer: {
    display: "flex",
    flexWrap: "wrap",

    [ScreenSizes.xlAndAbove]: {
      gap: "1.5rem",
      flexWrap: "nowrap",
    },
  },
  insightsContent: {
    ...panelStyles,
    borderLeft: "none",
    borderRight: "none",
    borderRadius: 0,
    padding: "1rem",
    width: "100%",
    marginBottom: "1rem",
    position: "relative",
    boxShadow: "none",
    ":last-child": {
      marginBottom: 0,
    },
    [ScreenSizes.mdAndAbove]: {
      ...panelStyles,
      ...panelStyles[ScreenSizes.mdAndAbove],
    },
    [ScreenSizes.mdOnly]: {
      margin: "0 1rem 1rem",
      width: "auto",
    },
    [ScreenSizes.xlAndAbove]: {
      ...panelStyles,
      ...panelStyles[ScreenSizes.xlAndAbove],
      padding: "1.5rem",
    },
  },
  insightsContentNoPadding: {
    padding: 0,

    [ScreenSizes.mdAndAbove]: {
      padding: 0,
    },
  },
  insightsContentNoBackground: {
    background: "none",
    borderRadius: 0,
    border: "none",
  },
  section: {
    marginBottom: "1.5rem",

    ":last-child": {
      marginBottom: 0,
    },
  },
  mainContactContainer: {
    border: `1px solid ${colours.borderGrey}`,
    borderRadius: 4,
    padding: "0.625rem",
  },
  showToggleArrow: {
    margin: 0,
    marginLeft: "0.25em",
  },
  showToggleText: {
    ...gStyles.avalonBold,
    fontsize: "0.75rem",
    margin: 0,
  },
};

export const primaryTitleStyles = {
  titleContainer: {
    width: "100%",
    padding: "0 1rem",
    marginBottom: "1rem",
    marginLeft: "1rem",

    [ScreenSizes.smAndAbove]: {
      padding: "0 1rem",
      marginLeft: "0",
    },
    [ScreenSizes.lgAndAbove]: {
      padding: "3rem 0 1.5rem",
    },
  },
};

export const secondaryTitleStyles = {
  title: {
    fontSize: "1rem",
  },
  titleContainer: {
    ...gStyles.avalonBold,
    padding: "0 1rem",
    marginLeft: "1rem",
    marginBottom: "0.75rem",
    fontSize: "1rem",

    [ScreenSizes.lgAndAbove]: {
      padding: 0,
      marginBottom: "1rem",
      marginLeft: 0,
    },
  },
  inlineContent: {
    width: "max-content",
    marginTop: 0,
  },
};

export const sectionTitleStyles = {
  titleContainer: {
    paddingBottom: "0.75rem",
    fontSize: "1rem", // "0.875rem",

    [ScreenSizes.smAndAbove]: {
      fontSize: "1rem", // "0.875rem",
      paddingBottom: "0.75rem",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: "1rem", // "0.875rem",
      paddingBottom: "1rem",
    },
  },
};
